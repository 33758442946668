.center {
  display: flex;
  align-items: center;
}
.iconWrapper {
  font-size: 14px;
  width: 32px;
  min-width: 32px;
  height: 32px;
  color: var(--color-blue);
  background: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    span,
    svg {
      background: linear-gradient(
        to bottom right,
        #0069a7 0%,
        #009fe3 100%,
        #0069a7 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
    }
  }
  &:disabled {
    border: 1px solid var(--inactive);
    color: var(--inactive);
    span {
      color: var(--inactive);
    }
    &:hover {
      span,
      svg {
        -webkit-text-fill-color: var(--inactive);
        color: var(--inactive);
      }
    }
  }

  svg {
    width: 16px;
    height: 16px;
    font-size: 16px;
    cursor: pointer !important;
  }
}

.large {
  width: 40px;
  min-width: 40px;
  height: 40px;
  span {
    font-size: 24px;
  }
}

.active {
  background: linear-gradient(
    to bottom right,
    #0069a7 0%,
    #009fe3 100%,
    #0069a7 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
/******************************DROPDOWN CONTENT*****************************/
.dropdownContent {
  position: absolute;
  width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  background: white;
  z-index: 5;
  border-radius: 8px;
  padding: 16px;
  text-transform: capitalize;
  border-radius: 20px;
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 15px;
  & > *,
  .list-content > * {
    color: var(--text-color);
    text-decoration: none;
    display: block;
    cursor: pointer;
    font-size: 14px !important;
    font-weight: 500;
    padding: 0px;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    // text-transform: capitalize;

    &:hover {
      text-decoration: underline;
      // opacity: 0.4;
    }
  }
}
/******************************CARDS WRAPPER********************************/
.cardsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 2.5rem;
  margin-bottom: 25px;
  .card {
    height: 300px;
    flex: 1;
    min-width: calc(20% - 2.5rem);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    position: relative;

    flex: 0 1 16%;
  }
}
/*****************************FOOTER****************************************/
.footer {
  position: unset;
  right: 0;
  left: 0;
  bottom: 0;
  color: #fff;
  background: #fff;
  margin: 20px 200px;
  .footerInner {
    padding-bottom: 44px;
    display: flex;
    justify-content: center;
  }
  .logo {
    width: 50px;
    height: 37.902px;
  }
  .title {
    font-size: 14px;
    font-weight: 600;
    color: var(--curagita-blue);
  }
  .footerNav {
    display: flex;
    gap: 136px;
    .footerNavDiv {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
  .footerLogo {
    margin-right: 135.5px;
  }
  .footerText {
    display: flex;
    align-items: center;
    color: var(--light-black);
    font-size: 14px;
    font-weight: 300;
    gap: 5px;
    a:hover {
      text-decoration: underline;
    }
  }

  .mini {
    display: flex;
    justify-content: center;
    gap: 60px;
    margin: auto;
    color: var(--light-black);
    font-size: 12px;
    font-weight: 300;
  }
}
/*****************************CHIP LABELS**********************************/
.chip {
  border-radius: 10px;
  color: var(--text-light-black);
  background: var(--light-gray);
  font-size: 16px;
  font-weight: 700;
  padding: 8px 12px;
  height: 26px;
  justify-content: center;
  //  text-transform: capitalize;
  @extend .center;
  cursor: pointer;
  &.bgNone {
    border-radius: 10px;
    border: 1px solid var(--Inactive, #aeaeae);
    gap: 4px;
    padding: 5px 12px;
    height: 34px;
    background: none;
    font-weight: 300;
    width: max-content;
    &:hover {
      //   border: 1px solid var(--curagita-blue);
    }
  }
  &.pending {
    background: var(--color-yellow);
    color: #fff;
  }
  &.success {
    background: var(--color-green);
    color: #fff;
  }
  &.cancelled,
  &.canceled {
    background: var(--color-red);
    color: #fff;
  }
  &.refunded {
    background: var(--dark-gray);
    color: #fff;
  }
}
.tag {
  border-radius: 10px;
  border: 1px solid var(--Light-Gray, #dcdcdc);
  background: var(--White, #fff);
  color:var(--light-black);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 0 16px;
  height: 33px;
  border: 1px solid transparent;
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);
  &:hover{
border: 1px solid var(--curagita-blue);
  }
  &.tagActive{
    background: var(--curagita-blue);
    color: #fff !important;
  }
}

/*****************************FILTER COMPONENT*****************************/
.filterWrapper {
  position: relative;
  z-index: 7;
  .btn {
    border-radius: 20px;
    height: 100%;
    padding: 16px 24px;
    width: max-content;
    background: #fff;
    @extend .center;
    color: var(--curagita-blue);
    font-size: 16px;
    font-weight: 600;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);
    .icon {
      padding-right: 4px;
    }
  }
  .filters {
    position: absolute;
    top: 52px;
    width: 275px;
    display: flex;
    padding: 20px 32px;
    padding-bottom: 0;
    flex-direction: column;
    gap: 16px;
    z-index: 7;
    right: 0px;
    border-radius: 20px;
    overflow-y: auto;
    max-height: 60vh;
    background: var(--White, #fff);
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);
    .title {
      @extend .center;
      width: 100%;
      justify-content: space-between;
      padding: 0;
      h5 {
        font-size: 16px;
        font-weight: 700;
        color: var(--text-light-black);
      }
      .iconDown {
        color: var(--curagita-blue);
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }
    .selected {
      font-size: 14px;
      color: var(--text-dark-gray);
      font-weight: 300;
      text-transform: capitalize;
    }
    .filterCard {
      display: flex;
      gap: 16px;
      flex-direction: column;
      padding-bottom: 16px;
      border-bottom: 0.4px solid #aeaeae;
      .filterItems {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-height: 300px;
        overflow-y: auto;
        overflow-x: clip;
      }
    }
    .bottomBtn {
      position: sticky;
      background: #fff;
      bottom: 0;
    }
  }
}
/*****************************SLIDER**************************************/
.sliderWrapper {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  .slideContent {
    display: flex;
    flex-wrap: nowrap;
    padding: 10px;
    gap: 24px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .scrollBtn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 3;
  }
  .scrollLeft {
    left: 0;
  }
  .scrollRight {
    right: 0;
  }
}

@media screen and (max-width: 1200px) {
  .footer {
    padding-top: 70px;
    clip-path: ellipse(150% 100% at 50% 100%);
  }
}
