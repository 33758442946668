@import "../styles/placeholders";

.layout {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 450px);
}

.border-blend {
  border-image: linear-gradient(
      to bottom right,
      #0069a7 0%,
      #009fe3 25%,
      #bce4fa 100%
    )
    1;
  border-image-slice: 1;
}

.input-disabled {
  cursor: not-allowed;
  color: var(--color-grey) !important;
  background: var(--standard-grey) !important;
}

.navBtn {
  background: none;
  border: none;
  color: var(--dark-grey);
  // text-transform: capitalize;
  font-weight: 600;
  font-size: 16px;
  padding: 0px;

  &:focus {
    outline: none;
  }
}

.my-footer {
  position: inherit;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 10px 50px;
  color: var(--dark-grey);
  background: white;
  z-index: 10000;
  width: 100%;
  height: auto;
  border-top: 1px solid var(--color-grey);
}

.icon-link {
  color: var(--dark-color);
  font-size: 23px;
}

.icon {
  width: 14px;
  height: 14px;
}

.icon-wrapper {
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  background: none;
  border: none;

  svg {
    width: 23px;
    height: 23px;
  }
}

.icon-wrapper-s {
  padding-left: 10px;
}

.sort-btn {
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 25px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;

  p {
    font-size: 12px !important;
  }
}

.icon-link:hover {
  color: var(--dark-color);
}

.active {
  color: var(--text-color) !important;
  font-weight: 600 !important;
}

.cs_active {
  color: var(--color-blue) !important;
  font-weight: 600 !important;
}

.sidebar {
  display: none;
}

.sidebar-link {
  width: 100%;
  text-align: center;
  padding: 8px;
  position: relative;
}

.sidebar-link:hover {
  background: var(--color-grey);
}

.sidebar-icon {
  width: 45px;
  height: 45px;
  text-align: center;
  position: relative;
  padding: 5px;
  color: var(--dark-color);
  font-size: 23px;
}

.sidebar-dropdown {
  display: none;
  position: absolute;
  background-color: var(--color-grey);
  min-width: 215px;
  left: 59px;
  top: 0px;
  padding: 10px;
  text-align: left;
}

.sidebar-link:hover .sidebar-dropdown,
sidebar-dropdown:hover {
  display: block;
}

.sidebar-nav a {
  color: var(--dark-color);
  padding: 12px 15px;
  text-decoration: none;
  width: 100%;
  display: block;
  text-align: left;
}

.sidebar-nav a:hover {
  color: var(--dark-color) !important;
}

.dropdown-content a:hover,
.dropdown-content button:hover {
  color: var(--color-blue) !important;
}

.nav-wrapper {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: auto;
  padding: 0px 200px;
  align-items: center;
  height: 82px;
}

.static-bg {
  background: rgb(0, 14, 42);
  width: 100%;
  height: 100%;
  position: fixed;
  height: auto;
  top: 82px;
  width: 100%;
  z-index: 700;
  padding-left: 70px;
  padding-right: 70px;
}

.f-20 {
  flex: 20%;
}

.f-80 {
  flex: 80%;
}

.text-label {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0px;
  color: var(--text-color);
  text-transform: capitalize;
}

.mt-40 {
  margin-top: 40px;
}

.experience-form,
.education-form {
  .img-btn {
    color: gray;
    background-color: var(--color-grey);
    border-radius: 50%;
    font-size: 14px;
    height: 120px;
    width: 120px;
    font-weight: bold;
  }

  .upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}

.experience-form,
.education-form {
  border-left: 4px solid var(--color-blue);

  .new-btn {
    background: var(--color-blue);
  }
}

.form-name {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color);
  // text-transform: capitalize !important;
}

.new-btn {
  font-size: 15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  outline: none;
  border: none;

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.outline {
  outline: 2px solid red;
}

.education-form {
  .new-btn {
    background: var(--color-purple) !important;
  }
}

.right-item {
  right: 60px;
  align-items: right;
  position: relative;
  position: absolute;
  cursor: pointer;
  border-radius: 15px;
}

.all-icons-c {
  padding: 3px 10px;

  a,
  span {
    padding: 8px;
    border-radius: 25px;
    background: #fff;
    margin-left: 10px;
    font-size: 20px;

    svg {
      color: var(--color-blue);
    }
  }
}

.m-icon-btn {
  position: absolute;
  align-content: flex-end;
  right: 130px;
  background: white;
  color: var(--color-blue);
  border-radius: 50px;
  padding: 5px;
  bottom: -16x;
  font-size: 14px;
  padding: 3px 6px;

  a,
  span {
    padding: 8px;
    border-radius: 25px;
    background: #fff;
    margin-left: 10px;
    font-size: 20px;

    svg {
      color: var(--color-blue);
    }
  }
}

.form-control {
  color: var(--text-color) !important;
  font-size: 16px !important;

  &:focus {
    outline: none;
    color: var(--dark-grey) !important;
  }
}

.save-btn {
  background: var(--color-green);
  color: var(--light-green);
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
  padding: 3px 10px;

  &:disabled {
    cursor: not-allowed !important;
    opacity: 1;
  }
}

.pay-btn {
  width: 180px !important;
}

.filter-dropdown {
  position: relative;
  display: inline-block;

  .active {
    &::before {
      content: "\a0\a0\a0\a0\a0\a0\a0\a0";
      display: block;
      position: absolute;
      text-decoration: underline;
      //  width: 30px;
      overflow: hidden;
      top: 20px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.filter-name {
  color: var(--text-color);
  cursor: pointer;
  text-transform: capitalize;
  // font-weight: 700;
  margin-left: 15px;
  margin-right: 15px;
}

.filter-dropdown-content {
  position: absolute;
  top: 40px;
  background-color: white;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 20px;
  height: auto;
  border-radius: 20px;

  label {
    margin-bottom: 10px !important;
  }
}

.aboutMe {
  color: var(--dark-grey);
  min-height: 120px;
  height: 120px;
  resize: none;
}

.search-form {
  background: white;
  padding-right: 8px;
  padding: 8px;
  border-radius: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 30px;

  .form-label {
    @extend %text-blend;
    margin: 0px !important;
    font-size: 14px;
    font-weight: 700;
  }

  .form-control {
    border: none;
    box-shadow: none !important;
    height: calc(0.8em + 0.3rem + 2px);
    outline: none !important;
    padding: 0px;
    font-size: 14px !important;

    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  ::placeholder {
    color: var(--color-grey);
    font-size: 14px !important;
  }

  .form-group {
    margin-bottom: 0px !important;
    padding-right: 10px;
  }
}

.search-btn {
  background: var(--color-orange);
  width: 47px;
  height: 47px;
  border-radius: 50px;
  color: var(--color-orange);
  outline: none;
  font-size: 14px;
  margin-top: 3px;
}

.m-text {
  font-weight: 700;
}

.disabled {
  
  cursor: disabled !important;
  color: var(--inactive)
}

.nav-item {
  padding-right: 25px;

  .text-label {
    font-size: 14px !important;
    text-transform: capitalize;
  }
}

.cus-range {
  height: 80px;
}

.right-dropdown-content {
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  background: white;
  right: 5px;
  display: none;
}

.right-dropdown-content > * {
  color: var(--dark-color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  text-transform: capitalize;
}

.right-dropdown-content > *:hover {
  background-color: #ddd;
}

.right-dropdown:hover .right-dropdown-content {
  display: block;
  cursor: pointer;
}

.plan-name {
  color: var(--color-pink);
  font-weight: 700;
  text-transform: capitalize;
}

.ql-editor {
  font-size: 16px;
  font-family: Sarabun;
  padding: 0px !important;
}

/* Hide the browser's default radio button */
.container-c input {
  opacity: 0;
  cursor: pointer;
}

.container-c {
  padding: 20px;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 120px;
  background: var(--color-green);
  color: var(--light-green);
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  text-align: center;
  padding-top: 3px;
}

.container-c:hover input ~ .checkmark {
  background: white;
  color: var(--color-green);
  border: 1px solid var(--color-green);
  content: "Select";
}

.container-c input:checked ~ .checkmark {
  visibility: hidden;
  position: relative;
  background: white;
  color: var(--color-green);
  border: 1px solid var(--color-green);
}

.checkmark:after {
  background: var(--light-green);
  color: var(--color-green);
}

.container-c input:checked ~ .checkmark:after {
  content: "Selected";
  visibility: visible;
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
  background: white;
  color: var(--color-green);
  border: 1px solid var(--color-green);
  position: absolute;
  top: -5px;
  left: 10px;
  transform: translate(-50%, -50%);
  width: 120px;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
}

.container-c .checkmark:after {
  background: var(--light-green);
  color: var(--color-green);
}

.table {
  color: var(--light-black);

  .table-head {
    text-transform: capitalize;
  }

  th,
  td {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .b-code {
    text-decoration: underline;
    text-transform: capitalize;
  }
}

.loggedIn .my-nav {
  display: none !important;
}

.signin-content {
  position: absolute;
  top: calc(50% - 43px);
  left: 50%;
  transform: translate(-50%, -50%);

  .signin-sub {
    font-size: 11px;
    color: #cec6c4;
    text-align: left;
  }
}

.lg-text {
  font-size: 60px;
  font-weight: 700;
}

.btn-cont {
  height: 47px;
}

.orange-btn {
  background: var(--color-orange);
  border-radius: 25px;
  font-size: 14px;
  color: rgb(153, 77, 0);
  height: 46.59px;
  width: 88px;
}

.container-p {
  width: 100%;
  margin-top: 25px;

  .checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.interview-title {
  text-transform: capitalize;
  font-weight: 700;
}

.interview-list {
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    background: var(--color-grey);
  }
}

.interview-details {
  height: 300px;
  transition: height 2s;
}

.status {
  border-radius: 15px;
  font-size: 14px;

  .accepted {
    background: #dae49b;
    color: var(--light-green);
    padding: 5px;
    border-radius: 15px;
    padding-top: 5px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 5px;
  }

  .declined {
    color: #ffffff;
    border-radius: 50px;
    padding-top: 5px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 5px;
    background: var(--color-orange);
  }

  .accept {
    background: var(--color-green);
    color: #ffffff;
    border-radius: 50px;
    padding-top: 5px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 5px;
    margin-left: 10px;
  }

  .cancel {
    border-radius: 50px;
    padding-top: 5px;
    padding-left: 9px;
    padding-right: 9px;
    padding-bottom: 5px;
    background: var(--color-orange);
    color: #ffffff;
    margin-left: 12px;
  }
}

#google_translate_element select {
  background-color: #fff;
  color: var(--dark-color);
  border: none;
  border-radius: 3px;
  padding: 0px;
  margin-right: 0px;
  font-size: 14px;
  color: var(--dark-grey);
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.contact-icons {
  color: var(--color-orange);
}

.selected-message {
  background: var(--standard-grey) !important;
}

.messaging-section {
  margin-top: 82px;
  padding: 0px 50px;
}

.selected-chat {
  background-color: var(--standard-grey);
}

.chat-item {
  border-bottom: 1px solid var(--color-grey);
  cursor: pointer;

  &:hover {
    background-color: var(--standard-grey);
  }
}

.messaging-panel {
  overflow-y: hidden;

  .users-list {
    overflow-y: auto;
    height: auto;
    border-right: 1px solid var(--standard-grey);
    width: 30%;

    .user-list-box {
      background: white;
      border-bottom: 1px solid var(--standard-grey);
      padding: 10px;
      width: 100%;
      cursor: pointer;
      outline: none;
      border: none;

      &:hover {
        background: var(--standard-grey);
      }

      .img-name-box {
        .user-list-img-wrapper {
          border: none !important;

          .user-list-img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
          }
        }
      }

      .user-name-wrapper {
        padding-left: 20px;
        padding-top: 20px;

        .user-name {
          font-size: 14px;
          font-weight: 700;
          color: var(--dark-color);
          text-transform: capitalize;
        }
      }
    }
  }

  .receiver-name {
    font-size: 16px;
    font-weight: 700;
    // text-transform: capitalize;
    color: var(--text-color);
  }

  .receiver-name-main {
    &:hover {
      border-bottom: 1px solid var(--text-color);
    }
  }

  .view-message {
    padding-right: 0px !important;
    height: calc(100vh - 100px);

    .empty-state {
      width: 50%;
      margin: auto;
      margin-top: 100px;
    }

    .message-box {
      position: relative;
      height: auto;

      .main-conversation {
        height: calc(100vh - 300px);
        overflow-y: auto;
      }

      .receiver-detail-box {
        position: sticky !important;
        background: white;
        border-bottom: 1px solid var(--standard-grey);
        width: 100%;
        padding: 15px 0px;
        top: 0px;

        .receiver-img-wrapper {
          padding: 0px !important;
          background: var(--standard-grey);
          width: 30px;
          height: 30px;
          border-radius: 50px;
          object-fit: cover;
        }

        .receiver-name {
          font-size: 18px;
          font-weight: 700;
          text-transform: capitalize;
          cursor: pointer;
          color: var(--text-color);
        }
      }

      .chat-box {
        width: 100%;
        padding: 5px;

        .receiver-img-wrapper {
          padding: 0px !important;
          background: var(--turqoise-color);
          width: 40px;
          height: 40px;
          border-radius: 50px;
          object-fit: cover;
        }

        .receiver-text-wrapper {
          margin-left: 10px;
          width: auto;
          padding: 8px 16px !important;
          color: #01384e;
          border-radius: 2px;
          background: var(--standard-grey);
        }

        .my-text-box {
          text-align: right !important;
          justify-content: end;

          .my-text-wrapper {
            padding: 8px 16px !important;
            background: #f4f5fa;
            color: #01384e;
            opacity: 0.6;
            border-radius: 2px;
            padding: 5px 8px;
            margin-right: 10px;
          }

          .my-img-wrapper {
            padding: 0px !important;
            background: var(--standard-grey);
            width: 40px;
            height: 40px;
            border-radius: 50px;
          }
        }
      }

      .text-box {
        padding: 10px 0px;
        position: sticky;
        margin-top: 20px;
        bottom: 0;
        width: 100%;
        background: white;

        .form-inline {
          background: var(--standard-grey);

          padding-right: 5px;
          border-radius: 25px;

          .text-input {
            flex: 60%;

            .message-input {
              background: #ffffff;
              width: 100% !important;
              outline: none !important;
              border: none !important;
              border-radius: 50px;

              &:focus {
                box-shadow: none !important;
              }
            }
          }

          .text-button {
            color: var(--color-blue);
            background: var(--turqoise-bg);
            border-radius: 50px;
          }
        }
      }
    }
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.file-input-btn {
  color: var(--color-blue);
  background-color: transparent;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.react-input-emoji--button {
  &:focus {
    outline: none;
  }
}

.cal-days {
  padding-left: 50px;
  padding-right: 50px;
}

.cal-time {
  padding-top: 10px;
  padding-bottom: 10px;
}

.reset-form {
  width: 390px;
  margin: auto;
  border-radius: 25px;
}

.signup-text {
  height: 46.59px;
}

.w-27 {
  width: 27% !important;
}

.with-icon {
  .input-icon {
    position: absolute;
    right: 0;
    top: 1px;
    color: var(--dark-grey);
  }
}

.total {
  font-weight: 700;

  .with-icon {
    .form-control {
      color: var(----color-green) !important;
    }

    .input-icon {
      position: absolute;
      right: 0;
      top: 1px;
      color: var(----color-green);
    }
  }
}

.demo-calendar {
  vertical-align: middle;
  text-align: center;
  width: 20%;
  // position: fixed;
  top: 190px;
  left: 50px;

  .calendar-button {
    font-size: 16px;
    // font-weight: 700;
    padding-top: 5px;
    cursor: pointer;
  }

  .calendar-label {
    font-size: 16px;
    // font-weight: 700;
    color: var(--dark-color);
    width: max-content;
  }

  .week-day-row {
    .week-day {
      padding: 10px;
      color: var(--dark-color);
      text-align: center;
      text-transform: capitalize;
      font-weight: 400;
    }
  }

  .calendar-day {
    text-align: center;
    padding: 5px;
    color: var(--dark-color);
  }

  .today {
    color: #fff;
    background-color: var(--dark-color);
    padding: 8px 3px;
    border-radius: 50px;
  }

  .days-table {
    margin: 10px;
  }
}

.mini-calendar {
  vertical-align: middle;
  text-align: center;
  width: 20%;
  position: fixed;
  top: 200px;
  left: 70px;

  .calendar-button {
    font-size: 16px;
    font-weight: 700;
    padding-top: 5px;
    cursor: pointer;
  }

  .calendar-label {
    font-size: 18px;
    font-weight: 700;
    color: var(--text-color);
    width: max-content;
  }

  .week-day-row {
    .week-day {
      padding: 10px;
      color: var(--text-grey);
      text-align: center;
    }
  }

  .calendar-day {
    text-align: center;
    padding: 5px;
    color: var(--text-color);
  }

  .today {
    font-weight: 700;
    color: var(--color-blue);
  }

  .days-table {
    margin: 10px;
  }
}

.week-header {
  width: 61.3%;
  z-index: 1;
}

.week-col {
  -ms-flex: 0 0 15.666667%;
  background: #ffffff;
  z-index: -1;
  flex: 0 0 15.666667%;
  max-width: 15.666667%;
  text-align: center;
  padding-top: 5px;
  text-transform: capitalize;

  .weekDayName {
    font-size: 14px;
    color: var(--dark-color);
  }

  .weekDates {
    font-size: 24px;
    line-height: 0.5;
    color: var(--dark-color);
  }
}
.availabilities-cal {
  width: 105%;
}
.profile-week-col,
.select-time {
  text-align: center;

  .weekDates {
    color: var(--dark-color);
  }
}

.day-col {
  -ms-flex: 0 0 15.666667%;
  flex: 0 0 15.666667%;
  max-width: 15.666667%;
  height: 70px;
}

.time-row {
  margin-top: 80px;
}

.timeCol {
  font-size: 14px;
  color: var(--dark-grey);
  z-index: -1;
}

.f-25 {
  width: 20%;
}
.select-time-wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 5px;
}
.select-time {
  background: #bce4fa;
  color: var(--color-blue);
  margin-bottom: 10px !important;
  border-radius: 15px;
  border: none;
  outline: none;
  width: 140px;
  cursor: pointer;
  &:disabled {
    @extend .input-disabled;
  }
}

.calendar-col {
  padding-left: 6px;
  padding-right: 6px;
}

.event-card {
  background: white;
  position: absolute;
  right: 50px;
  -ms-flex: 0 0 15.666667%;
  flex: 0 0 15.666667%;
  max-width: 15.666667%;
  height: 70px;
}
.calendarWrapper {
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  height: calc(100vh - 250px);

  &::-webkit-scrollbar {
    display: none;
  }
}

.join-btn {
  background: var(--color-green);
  color: var(--light-green);
  border-radius: 25px;
  padding: 3px 8px;
  font-size: 12px;
}

.no-btn-err {
  border-radius: 25px;
  padding: 3px 8px;
  font-size: 12px;
  color: var(--dark-color);
}

.no-btn {
  background: var(--color-pink);
  color: #fff;
  border-radius: 25px;
  padding: 3px 18px;
}

.yes-btn-acc {
  background: var(--color-blue);
  color: #fff;
  border-radius: 25px;
  padding: 3px 18px;
  font-size: 12px;
}

.confirm-btn {
  justify-content: space-around !important;
}

.calendar-line {
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 70.1%;
  margin-left: 46px;
  position: absolute;
}

.icon-grey {
  color: var(--standard-grey);
}

.first-table-row {
  margin-top: 120px;
}

@media (min-height: 700px) {
  .hero {
    height: 100vh;
  }
}

.availabilities {
  scroll-margin-block-start: 310px;
  scroll-margin-block-end: 310px;
  // max-height: 680px;
}

.emoji-mart-title-label {
  display: none;
}

.emoji {
  position: absolute;
  bottom: 20px;
  right: 80px;
}

.emoji-mart-bar {
  display: none;
}

.emoji-mart-scroll {
  height: 240px !important;
}

.rating {
  font-size: 14px;
  font-weight: bolder;
}

.all-icons {
  padding: 3px 10px;
}

.all-icons-c {
  padding: 3px 10px;
  margin-top: 3px;

  a,
  span {
    padding: 8px;
    border-radius: 25px;
    background: #fff;
    margin-left: 10px;
    font-size: 20px;

    svg {
      color: var(--color-blue);
    }
  }
}

.all-icons a,
.all-icons span {
  padding: 8px;
  border-radius: 25px;
  background: #fff;
  margin-left: 10px;
  font-size: 14px;

  svg {
    color: var(--color-blue);
  }
}

.swiper-pagination {
  visibility: hidden;
}

.react-calendar__month-view__days__day--weekend {
  color: var(--dark-color) !important;
}

.react-calendar__navigation__arrow {
  display: none !important;
}

.react-calendar__navigation__label__labelText {
  text-align: left !important;
}

.react-calendar__navigation button {
  width: auto !important;
  min-width: 0;
}

.react-calendar__navigation__label {
  flex-grow: 0 !important;
}

.react-calendar__tile {
  flex: 0 0 10.2857% !important;
}

.react-calendar__month-view__weekdays__weekday {
  flex: 0 0 10.2857% !important;
  font-size: 14px;
}

.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  position: relative;
  width: 200px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 200px;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 12px;
  width: 12px;
  margin-top: 34px;
  pointer-events: all;
  position: relative;
}

.disabled {
  cursor: not-allowed;
}

.focus-candidate {
  background: var(--color-grey);
}

.avail-container {
  width: 100%;
  height: 300px;
  margin-top: 150px;

  .sync-icon-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .sync-btn {
    background: var(--color-green);
    color: var(--light-green);
    border-radius: 20px;
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
    padding: 3px 10px;
    margin-top: 20px;
  }
}

.sync-req {
  .sync-btn {
    border-radius: 20px;
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
  }
}

.profile-view {
  margin-top: 30px;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  background: var(--color-grey);
  outline: none;
  opacity: 0.7;
  border-radius: 25px;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  cursor: pointer;
  border-radius: 50%;
  background: var(--dark-color);
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--dark-color);
  cursor: pointer;
}

.with-span {
  span {
    padding-left: 4px;
  }
}

.box-btn {
  @extend %bg-blend;
  border-radius: 20px;
  padding: 8px;
  text-transform: capitalize;
  border: none !important;
  font-weight: 700;
}

.floatingInput {
  position: relative;
  padding-top: 23px !important;
  height: 60px;
  font-size: 12px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  text-align: left;
  font-size: 14px !important;
  padding-left: 8px;
  color: var(--dark-color);

  &:focus {
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #ced4da;
    border-color: none !important;
    color: var(--dark-color) !important;
  }
}

.salInput.floatingInput {
  background-image: url("../public/dropdown-icon.svg");
  background-repeat: no-repeat;
  background-position: right 5px bottom 16px;
  /* Adjust the values as needed */
  padding-right: 20px;
}

.edit-content {
  cursor: pointer;

  &:focus-visible {
    border: 1px solid #ced4da;
  }
}

.br-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-floating {
  position: relative;

  .floatingLabel {
    position: absolute;
    top: 8px;
    z-index: 10;
    font-size: 14px;
    padding-left: 8px;
    font-weight: 600;
  }
}

.brt-0 {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.blt-0 {
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.mini-search {
  color: var(--color-grey);
  gap: 50px;
  padding-bottom: 30px;

  .focus {
    // border: none !important;
    color: var(--dark-grey) !important;
    border-bottom: 1px solid var(--dark-grey);
  }

  .borderLine {
    @extend %bg-blend;
    height: 0.7px;
    margin: 2px;
  }

  button {
    background: none;
    border: none;
    color: var(--color-grey);
    width: 50px;

    svg {
      width: 28px;
      height: 28px;
    }
  }
}

.new-card {
  width: 20%;
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;

  .card-img {
    background: var(--color-grey);
    width: 100%;
    height: 200px;
    border-radius: 15px;
  }
}

.util-btn {
  font-size: 14px;

  &:hover {
    background: var(--color-grey);
  }
}

.flex-gap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: flex;
  //justify-content: space-between;
}

.css-11p1od0,
.css-19yef5k {
  background: var(--dark-color) !important;
  top: -20px !important;

  &::after {
    display: none !important;
  }
}

.loaderWrapper {
  position: fixed;
  top: 65px;
  left: 65px;
  width: calc(100% - 65px);
  height: calc(100% - 65px);
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    top: 137px;
    left: 0;
    width: 100%;
    height: 100%;
    //  backdrop-filter: blur(10px);
    background-color: #fff;
  }

  .loader {
    margin: auto;
    width: 200px;
    justify-content: center;
    text-align: center;
    opacity: 0;
    height: auto;
    animation: fadeAnimation 4s infinite;
  }
}
@keyframes fadeAnimation {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.3;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.f-loader {
  top: 0;
  left: 0;
}

.picker {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.disabled {
  cursor: not-allowed !important;
}

.MuiSlider-markLabel {
  font-size: 12px !important;
}

.react-simple-star-rating {
  vertical-align: auto !important;
}

.slick-prev::before,
.slick-next::before {
  color: var(--dark-grey) !important;
}

.slick-prev {
  margin-left: -5px !important;
}

.filters-dropdown {
  padding: 15px;
}

.mapWrapper {
  height: 300px;
  position: relative;

  &:first-child {
    position: relative !important;
  }
  div {
    border-radius: 15px;
  }
}
.formMap {
  .mapWrapper {
    height: 100vh;
  }
}
.gm-style-iw-d {
  h4 {
    font-size: 14px !important;
  }
}

.prediction {
  background: white;
  position: absolute;
  z-index: 10;
  width: 414.84px;
  height: 250px;
  overflow-y: auto;
  border-radius: 25px;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);

  & > *:not(:last-child) {
    border-bottom: 1px solid var(--color-grey);
  }

  .predictionBtns {
    text-align: left;
    padding: 12px 18px;
    width: 100%;

    &:hover {
      background: var(--color-grey);
    }
  }
}

.col-form-label {
  position: relative;

  span {
    position: absolute;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.059) !important;
    color: var(--dark-color);
    font-size: 14px;
    left: -210px;
    width: 180px;
    line-height: 14px;
    padding: 8px;
    border-radius: 10px;
    display: none;
    z-index: 10000;
    line-height: 16px;
  }

  &:hover span {
    display: inline-block;
  }
}

.card-details {
  align-items: center;
  font-weight: 700;

  .card-dots {
    display: flex;
    font-weight: 700;
    color: var(--dark-color);
    margin-left: 20px;
    margin-top: -1px;
  }
}

.paragraph-header {
  font-size: 16px;
  font-weight: 700;
  // text-transform: capitalize;
  word-break: break-all;
}

.box {
  margin-top: 15px;
  gap: 25px;
  flex-wrap: wrap;
}

.extra {
  margin-top: 10px;
}

.salary-input {
  width: 100px;
  border: 1px solid var(--color-grey);
  height: 30px;

  &:focus {
    border: 1px solid var(--color-grey);
    outline: none;
  }
}

.inline-input,
.inline-select,
.inline-select-alt,
.inline-textarea {
  height: 20px;
  min-width: 120px;
  border: 1px solid var(--color-grey);

  &:focus {
    border: 1px solid var(--color-grey);
    outline: none;
  }
}

.inline-select {
  width: auto;
}

.inline-select-alt {
  height: 30px;
  padding: 0px 10px;
}

.inline-textarea {
  height: auto;
}

.nav-btns {
  // padding: 0px 50px;
  padding-left: 0px;
  // margin-bottom: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .box-btn {
    padding: 12px 24px;
    background: var(--light-yellow);
    color: rgb(179, 179, 0);
    min-width: 120px;
  }

  .ghost_btn {
    background-color: rgba(206, 198, 196, 0.1);
    color: #cec6c4;
    height: 40px;
    width: 160px;
    border-radius: 25px;
    text-transform: capitalize;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.select-inline {
  background: none;
  width: 110px;
  margin-left: 10px;
}

.signature {
  margin-top: 100px;
  margin-bottom: 100px;
}

.sign-col {
  margin-left: 110px;
}

.pay-box {
  min-width: 60px;
  min-height: 80px;
  position: relative;
  border-radius: 8px;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;

  .status {
    position: absolute;
    top: -3px;
    left: -3px;
  }
}

.pink-btn {
  background: var(--light-pink);
  color: var(--dark-pink);
  font-weight: 700;
  border-radius: 25px;
  width: 250px;

  &:disabled {
    cursor: not-allowed;
  }
}

.yellow-btn {
  font-weight: 700;
  border-radius: 25px;
  width: 250px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: capitalize;
  background: var(--light-yellow);
  color: rgb(179, 179, 0);
}

.outline-btn {
  background: #fff;
  color: var(--color-blue);
  border: 1px solid var(--color-blue);
  padding: 10px 30px;
  border-radius: 25px;
}

.primary-btn,
.button,
.ghost-btn,
.dropdown-btn,
.file-dropdown-btn,
.secondary-btn {
  background-color: var(--curagita-blue);
  color: #fff;
  padding: 8px 30px;
  border-radius: 30px;
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);
  border: none;
  font-size: 16px;
  font-weight: 600;

  &:disabled {
    cursor: not-allowed;
    color: var(--inactive);
    border: 2px solid var(--inactive);
    background: #fff;
    box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.15);
    span {
      color: var(--inactive);
    }
    &:hover {
      background: #fff;
    }
  }

  &:hover {
    background: linear-gradient(180deg, #009fe3 10%, #0069a7 100%);
  }
}
.file-dropdown-btn {
  &:hover {
    background: #fff !important;
  }
}
.primary-btn {
  span {
    color: #fff;
  }
}

.secondary-btn {
  background-color: #fff;
  color: var(--curagita-blue);
  border: 1px solid var(--curagita-blue);
  &:hover {
    background: linear-gradient(
      to bottom right,
      #0069a7 0%,
      #009fe3 100%,
      #0069a7 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
}

.ghost-btn {
  background-color: transparent;
  color: var(--curagita-blue);
  border-radius: none;
  box-shadow: none;
  border: none;
  padding: 4px 30px;
  height: fit-content;
  width: fit-content;
  &:hover {
    background: transparent;
    color:#0069A7;
  }
}

.dropdown-btn {
  background-color: #fff;
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);
  color: var(--curagita-blue);
  font-weight: 700;
  border: 1px solid var(--curagita-blue);
  &:hover {
    background: #fff;
    span {
      background: linear-gradient(
        to bottom right,
        #0069a7 0%,
        #009fe3 100%,
        #0069a7 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
    }
  }
}

.file-dropdown-btn {
  background-color: #fff;
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);
  color: var(--curagita-blue);
  font-weight: 700;
  border: 1px solid #fff;
  padding: 6px 22px 6px 16px;
  // &.invalid {
  //   &:hover {
  //     background: #fff;
  //     span {
  //       background: linear-gradient(
  //         to bottom right,
  //         #0069a7 0%,
  //         #009fe3 100%,
  //         #0069a7 100%
  //       );
  //       -webkit-background-clip: text;
  //       -webkit-text-fill-color: transparent;
  //       background-clip: text;
  //       color: transparent;
  //     }
  //   }
  // }
  &.valid {
    border: 1px solid var(--Inactive, #aeaeae);
    box-shadow: none;
    font-weight: 300;
    padding: 5px 10px 5px 16px;
    background: #fff !important;
  }
}

.error-btn {
  border: 1px solid red;
  box-shadow: none;
}

.active-filter {
  span {
    background: linear-gradient(
      to bottom right,
      #0069a7 0%,
      #009fe3 100%,
      #0069a7 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
}

.table-responsive {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
}

.booking-table {
  position: relative;
  border-collapse: collapse;
  table-layout: fixed;
  margin-bottom: 0;
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;

  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  th,
  td {
    padding: 10px 12px !important;
    vertical-align: middle;
    text-transform: capitalize;
    border-top: none;
    text-align: center;
  }
}

.form-control {
  &:focus {
    box-shadow: none !important;
  }
}

.react-tel-input {
  .flag {
    width: 24px;
  }

  .selected-flag {
    width: 40px;

    .arrow {
      left: 35px !important;
    }
  }
  .special-label {
    display: block !important;
    color: var(--dark-grey);
    font-size: 12px;
    font-weight: 300;
    left: 8px !important;
    top: -10px !important;
  }
  .form-control {
    color: var(--light-black) !important;
    font-weight: 300 !important;
    width: 100% !important;
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
    background: transparent !important;
    border: 1px solid var(--color-grey);

    &:focus {
      color: var(--dark-grey);
      border-color: var(--curagita-blue) !important;
    }
    &:focus + .special-label {
      color: var(--curagita-blue) !important;
    }
  }

  .flag-dropdown {
    border: none !important;
    background: none;
  }
}

.invoice-title {
  background: var(--dark-color);
  color: #fff;
  font-size: 18px;
  padding: 9px 25px;
  text-transform: capitalize;
  font-weight: 700;
}

.invoice-logo {
  font-size: 70px;
  font-weight: 700;
  text-transform: lowercase;
  margin-bottom: 0px;
}

.logo-text {
  margin-top: -10px;
  word-spacing: -7px;
  letter-spacing: 5px;
  padding-right: 10px;
  text-transform: capitalize;
}

.invoice-main {
  margin-top: 100px;
}

.invoice-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100px;
  margin-top: 370px;
  color: var(--dark-grey);
  justify-content: space-between;
}

.invoice-th {
  border-bottom: 1px solid var(--color-grey);

  p {
    text-align: left;
    font-weight: 700;
    text-transform: capitalize;
  }
}

.invoice-td {
  padding-top: 10px;

  p {
    text-align: left;
    text-transform: capitalize;
  }
}

.col-street {
  flex: 20%;
  max-width: 20%;
}

.auth_inputField__vYT_W {
  width: 100%;
  min-height: 49px;
  border-radius: 50px;
  border: 1px solid var(--color-grey);
  padding: 5px 10px;
  font-size: 20px;
  color: var(--dark-grey);

  .reactSelect__control {
    border-style: none !important;
    border: none !important;
    background: transparent;
    padding: 0px;
  }

  .css-1rhbuit-multiValue {
    background: var(--color-grey);
  }

  .reactSelect__control--is-focused {
    border-color: transparent;
    box-shadow: none;
  }
}

.css-14el2xx-placeholder {
  color: var(--color-grey) !important;
}

.download-btn {
  background: var(--standard-grey);
  color: var(--color-blue);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: block;
  margin-left: auto;
}

.countNum {
  font-size: 14px;
  @extend %bg-blend;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  margin-left: 10px;
}

.signature {
  background: white;
}

.demo-container {
  margin: 30px 140px;

  .title {
    font-size: 16px;
    border-bottom: 2px solid;
    // background: linear-gradient(90deg, rgba(0, 19, 58, 1) 4%, rgba(84, 113, 142, 1) 1%, rgba(89, 119, 147, 1) 2%, rgba(190, 232, 248, 1) 43%, rgba(197, 240, 255, 1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.demo-number-input .form-control {
  margin-top: 0px !important;
}

.react-select-container {
  .react-select__control {
    border: 1px solid var(--color-grey);
  }

  .react-select__control--is-focused {
    border-color: var(--color-grey);
  }
}

.nav-icons {
  margin-top: -5px;
  margin-bottom: 8px;

  &:hover {
    opacity: 1;
  }

  svg {
    &:hover {
      opacity: 0.4;
    }
  }
}

// .helper{
//   // --reactour-accent: red;
//   color:red;

// }

.icon-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto !important;
}

.table_btn {
  position: absolute;
  top: 95%;
  left: calc(50% - 23px);
}

.fw-bold {
  font-weight: 600 !important;
}

.input-filter {
  outline: none;
  border: none;
  background: var(--standard-grey);

  &:focus {
    outline: none;
  }
}

.gridWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
  grid-gap: 30px;
  justify-content: space-between;
  @media screen and (max-width: 1600px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 60px;
    .card {
      aspect-ratio: auto;
    }
  }

  @media screen and (max-width: 1440px) {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 60px;
  }
  @media screen and (max-width: 1300px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
  }
}
