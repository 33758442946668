.animate-upload {
        position: relative;
        animation: fadeMove 800ms ease-in-out infinite;
}

@keyframes fadeMove {
        0% {
                transform: translateY(20px);
                opacity: 0;
}
        50% {
                transform: translateY(0);
                opacity: 1;
}
        100% {
                transform: translateY(-20px);
                opacity: 0;
        }
}

  input:disabled{
        background-color: inherit;
  }

  .editable-table-input-group > input:disabled,
  .editable-table-input-group > input:disabled::placeholder{
        color: var(--text-color);

  }

  .editable-table-input-group > input:disabled + span{
        color: var(--text-color);
  }

  .form-group {
        position: relative;
  }
      .form-group input,
      .form-group textarea {
        border: 1px solid #aeaeae;
        width: 100%;
        font-size: 16px;
        padding: 8px 16px;
        outline: none;
        color: #3b3b3b;
        background: transparent;
        border-radius: 8px;
        font-weight: 300;
        &:disabled{
          background: var(--Light-Gray, #F2F2F2);
        }
      }

      .form-group input::placeholder,
      .form-group textarea::placeholder {
        color: transparent; /* Or set it to the same color as the input background */
      }

      .form-group label {
        position: absolute;
        top: 50%;
        left: 12px;
        padding: 0 4px;
        color: #818181;
        font-size: 16px;
        font-weight: 300;
        pointer-events: none;
        transform: translateY(-50%);
        transition: all 0.3s ease-in-out;
      }

      .form-group-textarea label{
        top: 16px;
      }
      
      .form-group input:not(:focus),
      .form-group textarea:not(:focus) {
        border: 1px solid #aeaeae;
      }

      .form-group input:is(:focus),
      .form-group textarea:is(:focus) {
        border: 1.2px solid #009fe3;
      }

      .form-group input:not(:focus, :placeholder-shown) + label,
      .form-group textarea:not(:focus, :placeholder-shown) + label  {
        top: 0;
        padding: 0 4px;
        color: #818181;
        background: #fff;
        font-size: 12px;
      }

      .form-group input:is(:focus) + label,
      .form-group textarea:is(:focus) + label {
        top: 0;
        padding: 0 4px;
        color: #009fe3;
        background: #fff;
        font-size: 12px;
      }

      .error-input input,
      .error-input textarea{
        border: 1px solid red !important;
      }

      

  