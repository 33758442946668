@media screen and (min-width: 1500px) {
  .cardsWrapper {
    gap: 1.5rem;
    .card {
      flex: 0 1 10%;
      height: 300px;
      width: auto;
      min-width: calc(15% - 1.5rem);
    }
  }
}

//*****************************************************************************************\
//*************************LARGE SCREEN LAPTOPS********************************************\
//****************************************************************************************\
@media screen and (min-width: 1600px) and (max-width: 1899px) {
  .cardsWrapper {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (min-width: 1900px){
  .cardsWrapper {
    grid-template-columns: repeat(6, 1fr);
  }
}

//*****************************************************************************************\
//*************************SMALL SCREEN LAPTOPS********************************************\
//****************************************************************************************\

@media screen and (max-width: 1300px) {
  .demo-container {
    margin: 30px 120px;
  }
}

@media screen and (max-width: 1220px) {
  .home-menu {
    padding-right: 50px;
  }

  .report-section {
    width: 50%;
  }

  .report-section:nth-of-type(3),
  .report-section:nth-of-type(4) {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid #dee2e6;
  }

  .bar {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media screen and (max-width: 1200px) {
  table {
    width: max-content !important;
  }

  .messaging-panel {
    overflow-y: inherit;
    height: calc(100vh - 85px);
    .users-list {
      width:40%;
    }
  }

  .startMsgDropdown {
    left: -20px;
    min-width: 280px;
    width: auto;
    padding: 0px;
  }

  .messaging-panel .view-message {
    max-width: 100% !important;
  }

  .sign {
    .signin-content {
      width: 60%;
      .search-form {
        justify-content: space-between;
      }
    }
  }

  .booking-table {
    width: max-content;
  }

  .booking-contract {
    max-width: 80% !important;
    margin: auto;
    padding-right: 0px;
  }

  .documents-row-section {
    flex-direction: column;
    flex-wrap: inherit;
  }

  .main-columnC {
    padding-right: 0px;
    margin: auto;
  }

  .filter-dropdown-content {
    right: -100px;
  }

  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard .fc-button-group {
    margin-left: -5em;
  }

  .calendar-section {
    flex-direction: column !important;
    align-items: center !important;
  }

  .mini-calendar {
    position: inherit;
    width: max-content;
    margin: 0px auto 70px auto;
  }

  .f-25 {
    width: 100%;
  }

  .demo-container {
    margin: 30px 70px;
  }

  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard .fc-col-header {
    top: 570px;
    position: initial;
  }

  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard .fc-button-group {
    position: absolute;
    width: 96vw !important;
    margin-left: 0px;
    left: -17vw;
    top: 40px;
  }

  .big-schedule-calendar {
    position: relative;
  }

  .cardsWrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1120px) {
  .scroll-section-with-hidden-scrollbar-md {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
  }

  .scroll-section-with-hidden-scrollbar-md::-webkit-scrollbar {
    display: none;
  }

  .investment-section-container {
    min-width: 1200px;
  }

  .investment-section-container-half {
    min-width: 600px;
  }

  // .cardsWrapper {
  //   gap: 1.5rem;
  //   justify-content: space-between;
  //   .card {
  //     flex: 0 1 22%;
  //   }
  // }
}

@media screen and (max-width: 980px) {
  .messaging-panel {
    .users-list {
      width:45%;
    }
  }
  // .nav-wrapper {
  //   padding: 18px 50px;
  // }

  .logo_img {
    width: 50px;
    -o-object-fit: contain;
    object-fit: contain;
    height: -webkit-fill-available;
  }
}

@media screen and (max-width: 950px) {
  .home-menu {
    padding-right: 40px;
  }

  .demo-container {
    margin: 30px 40px;
  }

  .empty-msg-text {
    font-size: 2.3rem;
  }

  .cardsWrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

//*****************************************************************************************\
//************************************TABLETS**********************************************\
//****************************************************************************************\

@media screen and (max-width: 900px) {
  .sign {
    .signin-content {
      width: 70%;
    }
  }

  .search-page-filters {
    position: absolute;
    top: 77px;
    margin: 5px 0px;
    width: 85%;
    justify-content: center;
  }

  .messaging-panel .view-message .empty-state {
    width: 50vw;
  }

  .messaging-panel
    .view-message
    .message-box
    .text-box
    .form-inline
    .text-input
    .message-input {
    font-size: 16px !important;
  }
  .cardsWrapper {
    // justify-content: space-evenly;
    // .card {
    //   flex: 0 1 28%;
    // }
    .title {
      font-size: 12px !important;
    }
  }
}

@media screen and (max-width: 830px) {
  .first-table-row {
    margin-top: 150px;
  }
}

//*****************************************************************************************\
//************************************MOBILE**********************************************\
//****************************************************************************************\
@media screen and (max-width: 767px) {
  .cardsWrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .messaging-panel .users-list {
    border-right: none;
    width: 100%;
  }

  .report-section {
    width: 100%;
  }

  .report-section:nth-of-type(2){
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid #dee2e6;
  }

  .messaging-panel
    .view-message
    .message-box
    .text-box
    .form-inline
    .text-input
    .message-input {
    font-size: 14px !important;
  }

  .messaging-section {
    padding: 0px 50px;
  }

  .messaging-panel .view-message .empty-state {
    width: 60vw;
    margin-top: 50px;
  }

  .empty-msg-text {
    font-size: 2rem;
  }

  .filter-dropdown-content {
    right: 0px;
  }

  .filter-dropdown {
    position: initial;
  }

  .search-page-cont {
    margin-top: 200px;
  }

  .availabilities-cal {
    width: 100%;
    overflow-x: scroll;
  }
  .main-content {
    padding-left: 50px;
    padding-right: 50px;
    // margin-top:100px;
  }

  .booking-table:first-of-type {
    margin-top: 100px;
  }

  .flex-gap {
    // margin-top: 250px;
    & > * {
      flex: inherit;
    }
  }

  .sign {
    .signin-content {
      width: 90%;
    }
  }

  .lg-text {
    font-size: 50px;
  }

  .auth_inputField__vYT_W {
    font-size: 16px;
    min-height: auto;
  }

  .main-columnC {
    padding-right: 20px !important;
    margin-top: 50px;
    max-width: 90%;
  }

  .avail-cal {
    overflow-x: scroll;
  }

  .row-section {
    flex-direction: column;
  }

  .documents-row-section {
    flex-direction: column;
  }

  .booking-filters {
    position: absolute;
    top: 85px;
    justify-content: center;
    left: calc((100% - 371px) / 2);
  }

  .sign-col {
    margin-left: 0px;
    margin-top: 50px;
  }

  .signature-bottom {
    margin-top: 50px !important;
  }

  .top-deets {
    margin-top: 110px;
  }

  .calendar-section {
    margin-top: 150px;
  }

  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard .fc-button-group {
    width: 100vw !important;
    left: -16vw;
  }

  .home-menu {
    position: absolute;
    top: -5px;
    right: 0px;
    padding-right: 50px;
  }

  .login-menu-btn {
    border: 1px solid rgba(206, 198, 196, 0.1) !important;
    color: #cec6c4;
    svg {
      color: #cec6c4;
    }
  }

  .signin-content {
    position: inherit;
    top: 0;
    left: 0;
    transform: inherit;
    margin: 50px 100px 90px 100px;
    padding: 20px 0px;
  }

  .demo-container {
    margin: 50px 100px 90px 100px;
  }

  .my-footer {
    padding: 10px 20px;
    height: 100px;
  }

  .mini-search {
    padding: 60px 0px 30px 0px;
  }

  .card-details {
    margin-top: 20px;
  }

  .first-table-row {
    margin-top: 195px;
  }

  .video-tutorial {
    height: 18rem;
    width: 34rem;
  }

  
  .cardsWrapper {
    .card {
      flex: 0 1 30%;
    }
  }
}

@media screen and (max-width: 625px) {
  .cardsWrapper {
    .card {
      flex: 0 1 45%;
    }
  }
}

@media screen and (max-width: 600px) {
  .messaging-panel .view-message .empty-state {
    width: 70vw;
    margin-top: 20px;
  }

  .empty-msg-text {
    font-size: 1.5rem;
  }

  .first-table-row {
    margin-top: 160px;
  }

  .tab-header {
    position: absolute;
    top: 85px;
    left: calc((100% - 227px) / 2);
  }

  .nav-btns {
    padding: 0px;
  }

  .filter-dropdown-content {
    min-width: auto;
    width: 60vw;
    left: 19vw;
    right: 19vw;
  }

  .mini-search {
    flex-wrap: wrap;
    padding: 30px 0px 30px 0px;
  }

  .search-page-cont {
    margin-top: 300px;
  }

  .sign {
    .signin-content {
      width: 90%;
      .search-form {
        flex-direction: column;
        padding: 20px 30px;
        .start-border,
        .middle-border {
          margin-bottom: 20px;
        }
        .end-border {
          button {
            width: 100%;
          }
        }
      }
    }
  }

  // .nav-wrapper{
  //     padding: 18px 20px;
  // }

  // .main-content{
  //     margin-top: 130px;

  // }

  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard .fc-button-group {
    width: 80vw !important;
    left: -2vw;
  }

  // .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  //     transform:scale(0.8);
  // }

  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard
    .fc-button-group
    .fc-prev-button {
    text-align: left;
  }

  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard
    .fc-button-group
    .fc-next-button {
    padding-right: 0px;
  }

  .footer-sm-text {
    font-size: 14px;
  }

  .footer-lg-text {
    font-size: 20px;
  }

  .footer-text {
    font-size: 14px;
  }

  .signin-content {
    margin: 50px 50px 90px 50px;
  }

  .home-menu {
    top: 43px;
  }

  .demo-container {
    margin: 50px 50px 90px 50px;
  }

  .my-dropdown {
    font-size: 12px;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .mini-search {
    gap: 20px;
  }

  .video-tutorial {
    height: 18rem;
    width: 28rem;
  }

}

@media screen and (max-width: 550px) {
  .cardsWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .search-page-filters {
    width: 95%;
  }
  .messaging-panel
    .view-message
    .message-box
    .text-box
    .form-inline
    .text-input
    .message-input {
    font-size: 12px !important;
  }
  .nav-wrapper {
    padding: 18px 20px;
  }

  .messaging-section {
    padding: 0px 20px;
  }

  .video-tutorial {
    height: 18rem;
    width: 90vw;
  }

  .search-page-filters {
    // font-size: small;
    justify-content: center;
    flex-wrap: wrap;
    left: 0;
    margin: auto;
  }

  .my-dropbtn-ghost {
    .text {
      font-size: 16px;
    }
  }

  //    .top-btn{
  //        margin: 10px 10px 0px 0px !important;
  //     }

  //     .download-btn{
  //         margin-top:17px !important;
  //     }

  .fc-timegrid-body {
    width: 100% !important;
  }

  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
    transform: scaleX(1);
  }

  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard .fc-button-group {
    margin-left: 1.5em;
  }

  .signin-content {
    margin: 20px 20px 90px 20px;
  }

  .home-menu {
    top: 18px;
    padding-right: 20px;
  }

  .demo-container {
    margin: 50px 20px 90px 20px;
  }

  .end {
    margin-top: -3px;
  }

  .filter-dropdown-content {
    width: 70vw;
  }

  .filter-name {
    font-size: 12px;
  }

  .main-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .main-columnC {
    padding-right: 0px !important;
  }
}

@media screen and (max-width: 411px) {
  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard .fc-button-group {
    margin-left: 1.8em;
  }

  .booking-filters {
    // font-size: small;
    justify-content: center;
    flex-wrap: wrap;
    left: 0;
    margin: auto;
  }

  .booking-contract {
    max-width: 100% !important;
  }
  .cardsWrapper {
    .card {
      flex: 0 1 80%;
      .unFlipped {
        .top {
          .thumbnail {
            object-fit: cover;
          }
        }
      }
    }
    .title {
      font-size: 12px !important;
    }
  }
}
