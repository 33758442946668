@import "placeholders";

.myModal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 100;
  backdrop-filter: blur(10px);
  background: var(--BG-Overlay-black, rgba(0, 0, 0, 0.15));
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    width: 70px;
    height: 70px;
  }

  .mySuccessModalContent {
    // margin: 15% auto;
    width: 350px;
    min-height: 300px;
    border-radius: 10px;
    padding: 10px;
  }

  .myErrorModalContent {
    // margin: 15% auto;
    width: 350px;
    height: 330px;
    border-radius: 10px;
    padding: 10px;
 
  }

  .myErrorModalInnerContent {
    // margin: 15% auto;
    width: 350px;
    height: auto;
    border-radius: 20px;
    background-color: #fff;
    padding: 32px;
    position: relative;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
    .times {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }
  .innerContent {
    min-width: 350px;
    border-radius: 20px;
    background-color: #fff;
    padding: 32px;
    position: relative;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  }
  .modal_error {
    color: var(--color-pink);
    font-size: 16px;
    font-weight: 700;
  }

  .modal_info {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    @extend %text-blend;
  }

  .modal_success {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    color: #6dd100;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    color: #000;
  }
  .main_text {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    color: #000;
    white-space: break-spaces;
  }

  .sub_text {
    font-size: 16px;
    font-weight: 300;
    margin-top: 32px;
    white-space: break-spaces;
    color: #000;
    line-height: inherit;
  }

  .btn_group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 32px;
    -moz-column-gap: 24px;
    column-gap: 24px;

    .primary_btn,
    .error_btn,
    .ghost_btn,
    .success_btn {
      padding: 12px 30px;
      font-size: 16px;
      border-radius: 30px;
      font-weight: 600;
      box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);
    }

    .primary_btn {
      background: var(--curagita-blue);
      color: #fff;
      &:hover {
        background: linear-gradient(180deg, #009fe3 10%, #0069a7 100%);
      }
    }

    .error_btn {
      background: var(--color-pink);
      color: #fff;
      border: none;
    }

    .ghost_btn {
      background: #fff;
      color: var(--curagita-blue);
      border: none;
      &:hover {
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        background-image: linear-gradient(
          to bottom right,
          #0069a7 0%,
          #009fe3 100%,
          #0069a7 100%
        );
        -webkit-text-fill-color: transparent;
      }
    }

    .success_btn {
      background: #6dd100;
      color: #fff;
      border: none;
    }
  }

  @keyframes animatetop {
    from {
      top: -300px;
    }

    to {
      top: 0;
    }
  }

  .modalInput {
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--color-grey);
    width: 100%;
    padding: 10px;
    margin-top: 5px;

    &::placeholder {
      color: var(--dark-grey);
    }

    &:focus {
      border: none;
      outline: none;
      border-bottom: 1px solid var(--color-grey);
    }
  }
}

.times {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 25px;
  cursor: pointer;
}

//*****************************************************************************************\
//************************************TABLETS**********************************************\
//****************************************************************************************\
@media screen and (max-width: 1000px) {
  .myModal {
    .myErrorModalContent,
    .mySuccessModalContent {
      width: 45%;
    }
  }
}

//*****************************************************************************************\
//************************************MOBILE**********************************************\
//****************************************************************************************\
@media screen and (max-width: 767px) {
  .myModal {
    .myErrorModalContent,
    .mySuccessModalContent {
      width: 55%;
    }
  }
}

@media screen and (max-width: 600px) {
  .myModal {
    .myErrorModalContent,
    .mySuccessModalContent {
      width: 70%;
    }
  }
}

@media screen and (max-width: 500px) {
  .myModal {
    .myErrorModalContent,
    .mySuccessModalContent {
      width: 85%;
    }
  }
}

@media screen and (max-width: 400px) {
  .myModal {
    .myErrorModalContent,
    .mySuccessModalContent {
      width: 95%;
    }
  }
}
