@import "placeholders";
.activeIcon {
  background: linear-gradient(
    to bottom right,
    #0069a7 0%,
    #009fe3 100%,
    #0069a7 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.logoWrapper {
  background: #fff;
  position: fixed;
  width: 64px;
  height: 64px;
  padding-top: 19.87px;
  padding-left: 16px;
  z-index: 20;
  top: 0;
  .logo {
    width: 32px;
    height: 24.257px;
    cursor: pointer;
  }
}

.toolName {
  display: flex;
}
.tag {
  font-size: 26px;
  color: var(--text-dark-gray);
  font-weight: 700;
}

.navWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 19.975px 16px;
  padding-left: 144px;
  padding-right: 80px;
  position: fixed;
  z-index: 17;
  height: 64px;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 10px 2px 3px 0px rgba(0, 0, 0, 0.15);
  background: #fff;

  .navItems {
    margin-left: 67px;
    margin-right: 67px;
    display: flex;
  }

  .userIcon {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);
  }
  .userDetails {
    font-size: 16px;
    font-weight: 600;
    color: var(--curagita-blue);
    margin-right: 26px;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .toolName {
    display: flex;
    align-items: center;
    margin-right: 40px;
    @media screen and (max-width: 1440px) {
      margin-right: 20px;
    }
    img {
      margin-right: 8px;
    }
    h1 {
      font-size: 26px;
      font-weight: 700;
      line-height: 130%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 0px;
      @media screen and (max-width: 1440px) {
        font-size: 20px;
      }
    }
  }

  .toolNavItems {
    display: flex;
    & > a {
      font-size: 18px;
      color: var(--light-black);
      font-weight: 400;
      padding: 17px 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        text-decoration: none;
        border-bottom: 3px solid #dcdcdc;
      }
      @media screen and (max-width: 1440px) {
        font-size: 16px;
        padding: 19px 32px;
        &:hover {
          border-bottom: 2px solid #dcdcdc;
        }
      }
    }

    .dropdownNavItem {
      position: relative;
      cursor: pointer;
      & > a {
        font-size: 18px;
        color: var(--light-black);
        font-weight: 400;
        padding: 17px 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
          text-decoration: none;
          border-bottom: 3px solid #dcdcdc;
        }
        @media screen and (max-width: 1440px) {
          font-size: 16px;
          padding: 19px 32px;
          &:hover {
            border-bottom: 2px solid #dcdcdc;
          }
        }
      }
    }

    .active {
      border-bottom: 3px solid var(--curagita-blue);
      font-weight: 700 !important;
      &:hover {
        border-bottom: 3px solid var(--curagita-blue) !important;
      }
      @media screen and (max-width: 1440px) {
        border-bottom: 2px solid var(--curagita-blue);
        &:hover {
          border-bottom: 2px solid var(--curagita-blue)!important;
        }
      }
    }

    .activeSubNav {
      font-weight: 700;
    }

    .openNavDropdown {
      border-bottom: 3px solid #fff !important;
      &:hover {
        border-bottom: 3px solid #fff !important;
      }
    }
  }
}
.activeNavIcon {
  margin-right: 8px;
  margin-top: 2px;
}
.sideNav {
  width: 64px;
  position: fixed;
  height: 100%;
  top: 64px;
  z-index: 20;
  padding-top: 9.99px;
  background: #fff;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.15);
}
.navBtnWrapper {
  position: relative;
  display: flex;
}
.navBtn {
  color: var(--curagita-blue);
  padding-bottom: 23px;
  text-align: center;
  width: 100%;
  &:hover{
    @extend .activeIcon;
  }
  img {
    width: 32px;
    height: 32px;
  }
  .navText {
    font-size: 10px;
    font-weight: 300;
  }
}

.navDropdown {
  position: absolute;
  left: 61px;
  background: #fff;
  padding: 20px 32px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  top: 0;
  width: auto;
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);

  .navLink {
    color: var(--light-black);
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    padding: 0px;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .activeLink {
    font-weight: 600;
    text-decoration: underline;
  }
}
.account {
  position: relative;
  .usersPop {
    position: absolute;
    background: #fff;
    padding: 20px 32px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    top: 0;
    width: auto;
    right: 270px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 400px;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);

    .navLink,
    & > * {
      color: var(--light-black);
      font-size: 14px;
      font-weight: 300;
      text-align: left;
      padding: 0px;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .activeLink {
      font-weight: 600;
    }
  }
}
.mainContent {
  margin-top: 64px;
  margin-left: 64px;
  padding: 80px;
  padding-top: 0;
  margin-top: 0;
  .contentInner {
    padding-top: 24px;
  }
}
.pageHeader {
  background: #fff;
  position: fixed;
  top: 64.2px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  gap: 11px;
  flex-direction: column;
  margin-left: -80px;
  padding:  0px 80px;
  padding-top: 80px;
  width: calc(100% - 64px);
}
.header {
  width: 100%;
  .toolName {
    font-size: 28px;
    font-weight: 700;
    color: var(--light-black);
    margin-bottom: 60px;
    display: flex;
    align-items: center;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 42px;
  }
  .title {
    font-size: 26px;
    font-weight: 700;
    width: auto;
    margin-bottom: 0;
    color: var(--light-black);
    white-space: nowrap;
    line-height: normal;
  }

  .btn {
    background-color: var(--dark-color);
    color: #fff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    text-align: center;
    border: none;

    svg {
      margin-top: 5px;
      width: 12px;
    }

    &:focus {
      border: none;
      outline: none;
    }
  }

  .saveBtn {
    background: var(--dark-color);
    color: #fff;
    padding: 3px 14px;
    border-radius: 25px;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 700;
  }
}

.iconBtn {
  //   background: rgba(206, 198, 196, 0.1);
  font-size: 14px;
  width: 32px;
  height: 32px;
  color: var(--color-blue);
  background: #fff;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(120, 119, 119, 0.15);
  svg {
    width: 15px;
    height: 15px;
  }
}

.nav {
  background-color: #fff;

  .navComp {
    display: inline;
    position: relative;
    padding: 0.5rem 1rem;
  }

  .navDropdown {
    position: absolute;
    left: 0;
    background: white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 15px;
  }

  .navBtn {
    background: none;
    border: none;
    color: var(--text-color);
    // text-transform: capitalize;
    font-weight: 700;

    &:focus {
      outline: none;
    }
  }

  .active {
    font-weight: 700;
    color: var(--text-color);

    &::before {
      content: "\a0\a0\a0\a0\a0\a0\a0\a0";
      display: block;
      position: absolute;
      text-decoration: underline;
      width: 30px;
      overflow: hidden;
      top: 20px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.bookC {
  font-size: 14px;
  color: #fff;
  display: inline;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  margin-left: 10px;
  @extend %bg-blend;
}

.opt {
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid rgb(51, 204, 255);
  color: rgb(51, 204, 255);
  margin-left: 10px;
  border-radius: 15px;
  text-transform: capitalize;
}

.req {
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  background: rgb(214, 245, 255);
  color: rgb(51, 204, 255);
  margin-left: 10px;
  border-radius: 15px;
  text-transform: capitalize;
}

.sortDropdown {
  position: relative;

  .sortBtn {
    border: 1px solid var(--dark-color);
    color: var(--dark-color);
    padding: 3px 14px;
    border-radius: 25px;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 700;
  }

  .content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    a {
      color: var(--dark-color);
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }
  }

  &:hover .content {
    display: block;
  }
}
.contactButtonWrapper {
  position: relative;

  .contactButton {
    background: #fff;
    display: flex;
    width: 40px;
    height: 40px;
    // position: fixed;
    // right: 32px;
    // bottom: 32px;
    padding: 5px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    // z-index: 10;
    cursor: pointer;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);
    margin-right: 13px;
    span {
      font-size: 24px;
      color: var(--curagita-blue);
    }
  }
  .contactBtnActive {
    // background: linear-gradient(180deg, #009fe3 10%, #0069a7 100%);
    background: linear-gradient(
      to bottom right,
      #0069a7 0%,
      #009fe3 100%,
      #0069a7 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
  .contactPopup {
    width: 344px;
    position: absolute;
    background: #fff;
    padding: 20px;
    position: fixed;
    right: 133px;
    top: 55px;
    border-radius: 20px;
    z-index: 9;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
    .contactImg {
      background: #d9d9d9;
      width: 111px;
      height: 111px;
      border-radius: 20px;
      img {
        width: 111px;
        height: 111px;
        border-radius: 20px;
      }
    }
    .contactDetails {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    .name {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 0px;
    }
    .contactInfo {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > * {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        span {
          font-size: 16px;
          color: var(--curagita-blue);
        }
      }
    }
  }
}
.toTop {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--curagita-blue);
  position: fixed;
  right: 36px;
  bottom: 30px;
  z-index: 3;
  span {
    color: #fff;
  }
}
// /*****************************************************************************************\
// //************************************TABLETS**********************************************\
// //****************************************************************************************\
// @media screen and (max-width: 1500px) {
//   .navWrapper {
//     .toolName{
//       margin-right: 50px;
//     }

//     .toolNavItems{
//       a{
//         padding: 17px 20px;
//       }
//     }
//   }
// }

// @media screen and (max-width: 1350px) {
//   .navWrapper {
//     .toolName{
//       margin-right: 30px;
//     }

//     .toolNavItems{
//       a{
//         font-size: 16px;
//         padding: 19px 20px;
//       }
//     }
//   }
// }

//*****************************************************************************************\
//************************************TABLETS**********************************************\
//****************************************************************************************\
// @media screen and (max-width: 900px) {
//   .header {
//     padding: 50px 50px 0px 50px;
//   }
// }

@media screen and (max-width: 850px) {
  .nav {
    position: absolute;
    top: 82px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .navComp {
      padding: 0.2rem 1rem;
    }
  }
}

//*****************************************************************************************\
//************************************MOBILE**********************************************\
//****************************************************************************************\
@media screen and (max-width: 767px) {
  .header {
    // margin-top: 20px;
    padding: 70px 50px 0px 50px;

    .btn {
      margin-top: 20px;
    }
  }

  .nav {
    .navBtn {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 600px) {
  .header {
    .title {
      // margin-top: 20px;
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 500px) {
  .header {
    padding: 60px 20px 0px 20px;

    .title {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 400px) {
  .header {
    .title {
      font-size: 18px;
    }
  }
}
