.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fixedXs {
  width: 147px;
}
.fixedSm {
  width: 310px;
}
.fixedMd {
  width: 636px;
}
.fixed-xl {
  width: 1010px;
}
.bottomBtn {
  position: sticky;
  background: #fff;
  bottom: 0;
  padding-bottom: 0;
}
.pageHeader {
  background: #fff;
  position: fixed;
  top: 64.2px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  margin-left: -80px;
  padding:  0px 80px;
  padding-top: 80px;
  width: calc(100% - 64px);
}
.formContainer {
  margin-top: 64px;
  margin-left: 64px;
  padding: 80px;
  padding-top: 0;
  margin-top: 0;
  .header {
    display: flex;
    justify-content: space-between;
    background: #fff;
    display: flex;
    margin-left: -64px;
    margin-right: -64px;
    padding: 0px 64px;
    align-items: flex-end;
  }
  .title {
    display: flex;
    align-items: center;
    font-size: 26px;
    font-weight: 700;
    span {
      font-size: 32px;
      margin-top: 2px;
      color: var(--curagita-blue);
    }
  }
  .actions {
    display: flex;
    gap: 8px;
  }
  .content {

  }
  .components {
    display: flex;
    flex-direction: column;
    gap: 56px;
    .sectionTitle,
    .title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 24px;
      color: var(--text-light-black);
    }
    .section {
      display: flex;
      flex-direction: column;

      .check {
        @extend .center;
        justify-content: left;
        gap: 8px;
      }
    }
    .multiSection {
      display: flex;
      flex-direction: column;
      gap: 32px;
      .group {
        .title {
          font-size: 18px;
          font-weight: 700;
          color: var(--text-light-black);
          margin-bottom: 24px;
        }
      }
    }
    .formGroup {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
.box {
  background: #fff;
  padding: 32px;
  border-radius: 30px;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.2);
  .label {
    width: 100px;
  }
}
/*********************************BUTTONS********************************/
.navButtons {
  position: fixed;
  @extend .center;
  gap: 24px;
  bottom: 12px;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
}
.btn {
  font-size: 16px;
  font-weight: 600;
  @extend .center;
  width: max-content;
  height: 40px;
  border-radius: 30px;
  gap: 4px;
  line-height: 100%;
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);
  &:disabled {
    color: var(--inactive);
    box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.15);
    span {
      color: var(--inactive);
    }
  }
  &:hover {
    text-decoration: none;
  }
}
.bg-none {
  color: var(--curagita-blue);
  width: max-content;
  gap: 6px;
  padding: 4px 0px !important;
  background: none;
  border-radius: none;
  box-shadow: none;
  background: none;
  &:disabled {
    box-shadow: none;
  }
  &:focus {
    span:last-child {
      color: var(--Secondary-Medium-Blue, #0069a7);
      //   text-decoration: underline;
    }
  }
}
.bg-blue {
  background: var(--curagita-blue);
  color: #fff;
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);
  &:hover {
    background: linear-gradient(180deg, #009fe3 10%, #0069a7 100%);
  }
  &:focus {
    background: var(
      --Blue-Fade,
      linear-gradient(180deg, #009fe3 10%, #0069a7 100%)
    );
    box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.15);
  }

  span {
    color: #fff;
  }
  &:disabled {
    background: #fff;
    border: 2px solid var(--inactive);
    box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.15);

    span {
      color: var(--inactive);
    }
  }
}
.bg-white {
  position: relative;
  background: #fff !important;
  color: var(--curagita-blue) !important;
  border: 1px solid var(--curagita-blue);
  border-radius: 30px;
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  &:hover span {
    background: var(--blue-fade) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background-clip: text !important;
    color: transparent;
    line-height: 1.5;
  }
  &:hover {
    border: 1px solid var(--Secondary-Medium-Blue, #0069a7);
  }
  &:disabled {
    border: 1px solid var(--Inactive, #aeaeae);
    color: #aeaeae;
    pointer-events: none;
    box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.15);
  }
}

.bg-outline {
  background: #fff;
  color: var(--curagita-blue);
  border: 1px solid var(--curagita-blue);
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);
  &:hover {
    /* Text with gradient color */
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: linear-gradient(
      to bottom right,
      #0069a7 0%,
      #009fe3 100%,
      #0069a7 100%
    );
  }
  &:disabled {
    border: 1px solid var(--Inactive, #aeaeae);
    color: var(--Inactive, #aeaeae);
    box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.15);
  }
}

.btnDisabled {
  color: var(--inactive);
  cursor: not-allowed;
}
.bg-blue.btnDisabled {
  box-shadow: none;
  border: 1px solid var(--inactive);
}

.small {
  padding: 12px 20px;
}
.medium {
  padding: 15px 24px;
}
.large {
  padding: 16px 30px;
  height: 43px;
}

.activeFile {
  box-shadow: none;
  color: var(--text-light-black);
  border: 1px solid var(--inactive);
  font-weight: 700;
  padding: 8px 16px;
  background: #fff;
  .icon {
    color: var(--curagita-blue);
  }
  &:focus {
    border: 1px solid var(--curagita-blue);
  }
}

.iconBtn {
  font-size: 16px;
  font-weight: 600;
  @extend .center;
  color: var(--curagita-blue);
  width: max-content;
  border-radius: 30px;
  height: 41px;
  padding: 16px 24px;
  background: #fff;

  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);
  .icon {
    padding-right: 4px;
    font-size: 24px;
  }
}
.primaryBtn {
  background: var(--curagita-blue);
  color: #fff;
}
.saveBtn {
  position: fixed;
  bottom: 12px;
  left: 50%;
  height: 40px;
  transform: translate(-50%, -50%);
}

.countRow {
  display: flex;
  align-items: center;
  width: 147px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid var(--inactive);
  justify-content: space-between;
  &:focus {
    border: 1px solid var(--curagita-blue);
  }
  .count {
    color: var(--dark-color);
    text-align: center;
    border: none;
    width: 48px;
    height: 40px;
    border-top: 1px solid var(--inactive);
    border-bottom: 1px solid var(--inactive);
    &:focus {
      border: 1px solid var(--curagita-blue);
      outline: none;
    }
  }

  .countBtn {
    width: 48px;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: var(--curagita-blue);
    }
  }
}

/*********************************SELECT********************************/
.selectBtn {
  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
  border-radius: 30px;
  border: 1px solid var(--inactive);
  padding: 8px 10px 8px 16px;
  align-items: center;
  height: 42px;
  color: #3b3b3b;
  width: -webkit-fill-available;
  justify-content: space-between;
  cursor: pointer;
  overflow-x: auto;
  &:disabled {
    border-radius: 30px;
    border: 1px solid var(--Inactive, #aeaeae);
    background: var(--Light-Gray, #f2f2f2);
    color: var(--dark-gray);
    box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.15);
  }
  .selectInput {
    width: calc(100% - 24px);
    border: none;
    outline-style: none;
    height: 24px;
    font-size: 16px;
    font-weight: 300;
    z-index: 1;
    background-color: transparent;
  }

  .selectInputLabel {
    position: absolute;
    left: 17px;
    top: calc(50% - 12px);
    color: var(--Dark-Gray, #818181);
    font-size: 16px;
    font-size: 16px;
    font-weight: 300;
  }
}

.selectBtnDisabled {
  border-radius: 30px;
  border: 1px solid var(--Inactive, #aeaeae);
  background: var(--Light-Gray, #f2f2f2);
  color: var(--dark-gray);
  box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.15);
  cursor: not-allowed;
}
.error {
  border: 1px solid red !important;
}
.singleSelect {
  position: relative;

  .chevron {
    color: var(--curagita-blue);
  }
  .active {
    border: 1px solid var(--Curagita-Blue, #009fe3);
  }

  .size_sm {
    width: 110px;
   // min-width: 110px !important;
  }
  .size_md {
    width: 147px;
    min-width: 147px !important;
    span {
      max-width: 100px;
      overflow: hidden;
      white-space: normal;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }
  }
  .size_lg {
    width: 310px;
    min-width: 310px !important;
  }
}
.selectOption {
  margin-top: 16px;
}
.selectWrapper {
  position: relative;
  .error {
    border: 1px solid red;
  }

  .selectHeader {
    height: 42px;
    border: 1px solid var(--inactive);

    text-align: left;
    display: flex;
    align-items: center;
    // margin-top: 0.5rem;
  }

  .selectedItemChip {
    font-size: 16px;
    display: flex;
    align-items: center;
    width: 100%;
    color: #3b3b3b;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    gap: 4px;
  }
  .selectedItem {
    cursor: context-menu;

    .cancel {
      cursor: pointer;
      margin-left: 8px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  .selectInputWrapper {
    .selectInput {
      width: 100%;
      height: 40px;
      padding: 8px 16px;
      border-radius: 50px;
      border: 1px solid var(--color-grey);
      &:focus {
        outline: none;
      }
    }
  }
  .selectList {
    position: absolute;
    width: max-content;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
    background: white;
    right: 0px;
    left: 0px;
    z-index: 2;
    border-radius: 15px;
    top: auto;
    max-height: 250px;
    overflow-y: auto;
    padding: 8px 20px;
    text-transform: capitalize;
    transition: max-height 0.3s ease-in-out;
    width: -webkit-fill-available;

    .selectItem {
      color: var(--light-black);
      padding: 6px 0px;
      text-decoration: none;
      display: block;
      cursor: pointer;
      font-size: 14px;
      font-weight: 300;
      outline: none;
      border: none;
      width: 100%;
      text-align: left;

      &:hover {
        text-decoration: underline;
      }
    }

    .selected {
      font-weight: 600;
    }
  }
}

.dropdownContentContainer {
  position: absolute;
  width: fit-content;
  background: white;
  z-index: 2 !important;
  padding: 16px;
  border-radius: 20px;
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);

  .dropdownContent {
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-height: 355px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 159, 227, 0.4);
      border-radius: 10px;
      background-clip: padding-box;
    }
    &::-webkit-scrollbar-track {
      background: white;
      border: 1px solid rgba(0, 159, 227, 0.4);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:vertical:hover {
      background-color: var(--color-blue);
    }
    & > *,
    .list-content > * {
      color: var(--text-color);
      text-decoration: none;
      display: block;
      cursor: pointer;
      font-size: 14px !important;
      font-weight: 500;
      padding: 0px;
      text-align: left;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;

      &:hover {
        text-decoration: underline;
      }
    }
    .emptyOptionText {
      text-transform: none;
      margin: 0px 0px;
      cursor: default;
      &:hover {
        text-decoration: none;
      }
    }
    .selectedItem {
      font-weight: 700;
    }
  }
}

.emptyOptionText {
  text-transform: none;
  margin: 16px 0px;
  &:hover {
    text-decoration: none;
  }
}

/*********************************UPLOAD********************************/
.imgUploadWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .drop {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.uploadImg {
  height: 100%;
  border-radius: 20px;
  &:not(:has(img)) {
    background: var(
      --BG-Banner-gradient-big,
      linear-gradient(
        180deg,
        rgba(0, 159, 227, 0.5) 0%,
        rgba(0, 159, 227, 0.5) 50%,
        rgba(255, 255, 255, 0.5) 100%
      )
    );
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  }
  width: 100%;
  input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  img {
    object-fit: cover;
    height: 100%;
    border-radius: 20px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  }

  .uploadImgBtn {
    width: 70px;
    height: 70px;
    color: var(--color-grey);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loadingBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.round {
  border-radius: 50%;
}

/*********************************TEXT INPUTS********************************/
.floatingLabel {
  position: relative;
  width: 100%;
  input,
  textarea {
    padding: 15px 16px;
    border-radius: 8px;
    height: 42px;
    border: 1px solid var(--inactive);
    transition: all 0.2s ease-in-out;
    font-size: 16px;
    font-weight: 300;
    width: 100%;
    color: #3b3b3b;
    &:focus + label {
      color: var(--curagita-blue) !important;
    }
    &:disabled {
      background-color: var(--light-gray);
    }
  }
  textarea {
    height: 96px;
  }

  label {
    position: absolute;
    top: 20px;
    left: 12px;
    transform: translateY(-50%);
    background-color: white;
    padding: 0 4px;
    color: var(--dark-gray);
    transition: all 0.2s ease;
    pointer-events: none;
    font-size: 16px;
    font-weight: 300;
  }

  input:focus + label,
  input.notEmpty + label,
  textarea:focus + label,
  textarea.notEmpty + label {
    top: 0;
    left: 8px;
    color: var(--curagita-blue);
    font-size: 12px;
  }
  input:disabled + label {
    background: none;
  }
  input.notEmpty + label {
    background: #fff !important;
  }
  input.notEmpty + label,
  textarea.notEmpty + label {
    color: var(--dark-gray);
  }

  input:focus,
  textarea:focus {
    border: 1.2px solid var(--curagita-blue);
    outline: none;
  }
}
.error {
  input {
    border: 1px solid red;
  }
}
.pText {
  color: var(--Inactive, #aeaeae);
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}
.searchBar {
  width: 200px;
  position: relative;
  input {
    width: 100%;
    border: 1px solid var(--curagita-blue);
    outline: none;
    color: var(--light-black);
    font-size: 14px;
    font-weight: 300;
    //  height: 32px;
    border-radius: 30px;
    padding: 8px 12px;
    &::placeholder {
      color: var(--text-dark-gray);
    }
    &:focus {
      outline: none;
    }
  }
  .searchIcon {
    width: 24px;
    height: 24px;
    color: var(--curagita-blue);
    position: absolute;
    right: 12px;
    font-size: 24px;
    top: 8px;
  }
}
.inputRow {
  width: 339px;
  @extend .center;
  justify-content: space-between;
  gap: 24px;
  input,
  select {
    width: 147px;
  }
  .label {
    font-size: 16px;
    font-weight: 300;
  }
}
.pricingInput {
  position: relative;
  input {
    height: 42px;
    border-radius: 8px;
    border: 1px solid var(--Inactive, #aeaeae);
    text-align: right;
    padding: 15px 16px;
    padding-right: 30px;
    font-weight: 300;
    color: var(--light-black);
    &:focus {
      outline: none;
      border: 1px solid var(--curagita-blue);
    }
    &:disabled {
      background: var(--light-gray);
    }
  }
  .currency {
    position: absolute;
    right: 16px;
    top: 10px;
  }
}
/*********************************CUSTOM CHECKBOX********************************/
.customCheckbox {
  display: flex;
  align-items: center;
  width: max-content;

  input[type="checkbox"],
  input[type="radio"] {
    appearance: none;
    width: 14px;
    height: 14px;
    border: 1px solid var(--dark-gray);
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease;
    margin-right: 8px;

    &:checked {
      border-color: var(--curagita-blue);
      background-color: var(--curagita-blue);

      &::after {
        content: "";
        position: absolute;
        top: 0.5px;
        left: 3px;
        width: 5px;
        height: 8px;
        border: solid white;
        border-width: 0 1.5px 1.5px 0;
        transform: rotate(45deg);
      }
    }

    &:hover {
      border-color: var(--curagita-blue);
    }
  }

  label {
    cursor: pointer;
    user-select: none;
    font-size: inherit;
    font-weight: 300;
    color: var(--text-light-black);
    display: flex;
    align-items: center;
  }
}

.checkboxError {
  input[type="checkbox"],
  input[type="radio"] {
    border: 1px solid red;
  }
}

.checkPill {
  border-radius: 10px;
  border: 1px solid var(--Inactive, #aeaeae);
  gap: 4px;
  padding: 5px 12px;
  color: var(--text-light-black);
  height: 34px;
  @extend .center;
  &:hover {
    border: 1px solid var(--curagita-blue);
  }
}
.checked {
  border: 1px solid var(--curagita-blue);
  .icon {
    color: var(--curagita-blue);
    font-size: 24px;
  }
}
.checkWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 32px;
  background-color: #fff;
  border: 2px solid var(--curagita-blue);
  border-radius: 100px;
  cursor: pointer;
  padding: 0;
  transition: background-color 0.4s;

  &.off {
    background-color: #fff;
    .toggleThumb {
      background: var(--curagita-blue);
      .icon {
        color: #fff;
      }
    }
  }

  &.on {
    border: none;
    background: linear-gradient(180deg, #009fe3 10%, #0069a7 100%);
    .toggleThumb {
      background: #fff;
      .icon {
        color: var(--curagita-blue);
      }
    }
  }
  .toggleThumb {
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    bottom: 0;
    border-radius: 24px;
    @extend .center;
    transition: 0.4s;
    width: 24px;
    height: 24px;
    .icon {
      font-size: 16px;
      transition: transform 0.4s;
    }
  }
  &.on .toggleThumb {
    transform: translateX(26px);
  }
  &.off .toggleThumb {
    transform: translateX(2px);
  }
}

.card {
  width: 320px;
  margin-top: 0;
  .formGroup {
    margin-top: 16px;
    margin-right: 16px;
  }
  .count {
    text-align: right;
    font-size: 14px;
    color: var(--dark-gray);
    margin-right: 16px;
  }
}
