.input-xs {
  --input-xs-width: calc((200 / 1216) * 100%);
  -ms-flex: 0 0 var(--input-xs-width);
  flex: 0 0 var(--input-xs-width);
  max-width: var(--input-xs-width);
}
.input-sm {
  --input-sm-width: calc((310 / 1216) * 100%);
  -ms-flex: 0 0 var(--input-md-width);
  flex: 0 0 var(--input-sm-width);
  max-width: var(--input-sm-width);
  @media screen and (min-width: 1440px) {
  }
}
.input-md {
  --input-md-width: calc((636/ 1216) * 100%);
  flex: 0 0 var(--input-md-width);
  width: var(--input-md-width);
  @media screen and (min-width: 1600px) {
    --input-md-width: calc((636 / 1600) * 100%);
  }
}
.input-lg {
  --input-lg-width: calc((1010 / 1216) * 100%);
  flex: 0 0 var(--input-lg-width);
  width: var(--input-lg-width);
}
.lh-inherit {
  line-height: inherit !important;
}
.primary-link {
  font-size: 16px;
  font-weight: 600;
  color: var(--curagita-blue);
  &:hover {
    text-decoration: underline;
  }
  &.disabled {
    color: var(--inactive);
    &:hover{
      text-decoration: none;
    }
  }
}
.hover-underline{
  &:hover{
    text-decoration: underline;
  }
}
.fixed-center {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
}
.absolute-center{
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-col {
  flex-direction: column;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-40 {
  font-size: 40px !important;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.mt-8 {
  margin-top: 8px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-24 {
  margin-top: 24px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-14 {
  margin-bottom: 14px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-32 {
  margin-bottom: 32px;
}

.mb-44 {
  margin-bottom: 44px;
}
.mb-56{
  margin-bottom: 56px;
}
.gap-4 {
  gap: 4px;
}
.gap-6 {
  gap: 6px;
}
.gap-8 {
  gap: 8px;
}
.gap-12 {
  gap: 12px;
}
.gap-14 {
  gap: 14px;
}
.gap-15{
  gap: 15px;
}
.gap-16 {
  gap: 16px;
}
.gap-20 {
  gap: 20px;
}
.gap-24 {
  gap: 24px;
}
.gap-32 {
  gap: 32px;
}
.gap-40 {
  gap: 40px;
}
.gap-48{
  gap: 48px;
}
.gap-56 {
  gap: 56px;
}
.gap-80{
  gap: 80px;
}
.hidden{
  display: none;
}