html,
body {
  padding: 0;
  margin: 0;
  min-height: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
input:disabled{
  cursor: not-allowed;
}