$number-of-options: 7;


.slider-wrapper {
    width: 100%;

    .css-1aznpnh-MuiSlider-root.Mui-disabled {
        color: var(--color-blue);
    }
    .css-eg0mwd-MuiSlider-thumb:hover, .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible{
        box-shadow: none !important;
    }
    .css-1aznpnh-MuiSlider-root {
        color: var(--dark-color);
        font-family: "Source Code Pro", monospace;
    }

    .css-yafthl-MuiSlider-markLabel {
        font-size: 14px !important;
        color: var(--dark-color);
        font-family: "Source Code Pro", monospace;
    }
    .css-1eoe787-MuiSlider-markLabel{
        font-size: 14px !important;
        font-family: "Source Code Pro", monospace;
    }
    #range-level-slider {
        display: flex;
        flex-direction: row;
        align-content: stretch;
        position: relative;
        width: 100%;
        height: 50px;
        user-select: none;

        &::before {
            content: " ";
            position: absolute;
            height: 6px;
            width: 100%;
            width: calc(100% * (#{$number-of-options - 1} / #{$number-of-options}));
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: var(--color-grey);
        }

        input,
        label {
            box-sizing: border-box;
            flex: 1;
            user-select: none;
            cursor: pointer;
        }

        label {
            display: inline-block;
            position: relative;
            width: 50px;
            height: 100%;
            user-select: none;

            &::before {
                content: attr(data-range-level);
                position: absolute;
                left: 50%;
                padding-top: 10px;
                transform: translate(-50%, 45px);
                font-size: 14px;
                letter-spacing: 0.4px;
                font-weight: 400;
                white-space: nowrap;
                opacity: 0.85;
                transition: all 0.15s ease-in-out;
            }

            &::after {
                content: " ";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 10px;
                height: 10px;
                border: 2px solid var(--dark-color);
                background: #fff;
                border-radius: 50%;
                pointer-events: none;
                user-select: none;
                z-index: 1;
                cursor: pointer;
                transition: all 0.15s ease-in-out;
            }

            &:hover::after {
                transform: translate(-50%, -50%) scale(1.25);
            }
        }

        input {
            display: none;

            &:checked {
                background-color: palevioletred;

                +label::before {
                    font-weight: 800;
                    opacity: 1;
                }

                +label::after {
                    border-width: 2px;
                    transform: translate(-50%, -50%) scale(0.75);
                }

                ~#debt-amount-pos {
                    opacity: 1;
                }

                @for $i from 1 through $number-of-options {
                    &:nth-child(#{$i * 7 - 1})~#debt-amount-pos {
                        left: #{($i * 10%) - 10%};
                    }
                }
            }
        }

    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
        width: 24px;
        opacity: 1;
        margin-right: 12px;
    }

    to {
        transform: rotate(360deg);
        width: 24px;
        opacity: 1;
        margin-right: 12px;
    }
}

#debt-amount-pos {
    display: block;
    position: absolute;
    top: 50%;
    width: 12px;
    height: 12px;
    background: #000;
    border-radius: 50%;
    transition: all 0.15s ease-in-out;
    transform: translate(-50%, -50%);
    border: 2px solid #fff;
    opacity: 0;
    z-index: 2;
}
