%text-blend {
  background: linear-gradient(
    to bottom right,
    #0069a7 0%,
    #009fe3 25%,
    #bce4fa 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

%bg-blend {
  background: #003044;
  background: linear-gradient(
    to bottom right,
    #0069a7 0%,
    #009fe3 25%,
    #bce4fa 100%
  );
}

%blue-blend {
  background: linear-gradient(
    to bottom right,
    #0069a7 0%,
    #009fe3 25%,
    #bce4fa 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

%textModal {
  position: absolute;
  width: 300px;
  z-index: 100000;
  top: 0px;
  display: flex;
  align-items: center;
  color: var(--text-color);
  min-height: 50px;
  padding: 12px;
  background: rgb(0, 24, 42);
  background: #fff;
  border-radius: 8px;
  font-size: 14px;
  text-align: left;
  text-transform: lowercase;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);

  p {
    font-size: 14px;
  }
}

%borderGradient {
  border-bottom: 1.6px solid;
  border-image: linear-gradient(
      to bottom right,
      #0069a7 0%,
      #009fe3 65%,
      #bce4fa 100%
    )
    1;
}
