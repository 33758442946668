@import url("https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

html {
  overflow-x: hidden;
}
body {
  font-family: "Sarabun", sans-serif;
  font-size: 16px;
  min-height: 0% !important;
  width: 100vw;
  overflow-x: clip;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  font-weight: 300;
  line-height: 140%;
}
.skiptranslate {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--dark-color);
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

:root {
  // --dark-color: #01384e;
  //New
  --curagita-blue: #009fe3;
  --medium-blue: #0069a7;
  --dark-blue: #004476;
  --extra-old-dark-blue: #01384e;
  --text-grey: #3b3b3b;
  --light-text: #009dd9;
  --light-black: #3b3b3b;
  --text-inactive: #aeaeae;
  --text-light-black: #3b3b3b;
  --dark-gray: #818181;
  --disabled: #818181;
  --inactive: #aeaeae;
  --label-red: #fc5e5e;
  --label-yellow: #ffc000;
  --label-green: #afca0b;
  --color-green: #afca0b;
  --color-yellow: #ffc000;
  --color-red: #fc5e5e;
  --light-gray: #f2f2f2;
  --text-dark-gray: #818181;
  --secondary-medium-blue: #0069a7;
  --blue-fade: linear-gradient(180deg, #009fe3 10%, #0069a7 100%);
  --image-fallback: linear-gradient(
    180deg,
    rgba(0, 159, 227, 0.5) 0%,
    rgba(0, 159, 227, 0.5) 50%,
    rgba(255, 255, 255, 0.5) 100%
  );
  //
  --dark-color: #333333;
  --old-dark-blue: rgb(23, 64, 109);
  --old-old-curagita-blue: #01384e;
  --text-font-weight: 425;
  --text-font-size: 16px;
  --title-font-size: 38px;
  --title-font-weight: 700;
  --text-line-height: 28px;
  --title-line-height: 32px;
  --colored: rgb(102, 188, 255);
  --button-text: rgb(102, 188, 255);
  --button-bg: rgb(221, 240, 255);
  --color-blue: #009fe3;
  --color-grey: #dddddd;
  --dark-grey: #818181;
  --turqoise-bg: rgb(224, 255, 255);
  --turqoise-color: rgb(0, 179, 179);
  --background-red: rgb(255, 222, 227);
  --text-red: #fc5e5e;
  --background-purple: rgb(255, 220, 247);
  --text-purple: rgb(255, 101, 220);
  --background-orange: rgb(255, 231, 215);
  --color-orange: #ffc000;
  --light-orange: rgb(255, 235, 214);
  --light-yellow: rgb(255, 255, 102);
  --dark-yellow: rgb(179, 179, 0);
  --light-blue: #bce4fa;
  --color-pink: #fe62a1;
  --color-purple: rgb(255, 204, 255);
  --light-green: rgb(0, 179, 0);
  --standard-grey: #f2f2f2;
  --light-pink: rgb(255, 214, 235);
  --dark-pink: #84026d;
  --text-color: #3b3b3b;
}

.text-blend {
  background: linear-gradient(
    to bottom right,
    #0069a7 0%,
    #009fe3 25%,
    #bce4fa 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.bg-blend {
  background: #0069a7;
  background: linear-gradient(
    to bottom right,
    #0069a7 0%,
    #009fe3 25%,
    #bce4fa 100%
  );
  background-position: center;
  /* Add this line */
  background-size: 100% 100%;
  /* Add this line */
}

.blue-blend {
  background: linear-gradient(
    to bottom right,
    #0069a7 0%,
    #009fe3 25%,
    #bce4fa 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.text-blend-doc {
  color: var(--text-color) !important;
}
.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  visibility: hidden;
}

.material-icons::before {
  content: attr(data-icon);
  visibility: visible;
}
p,
label {
  margin-bottom: 0px;
}

b {
  font-weight: 600;
}
button {
  padding-left: 0;
  padding-right: 0;
}
.completed {
  color: rgba(175, 202, 11, 1) !important;
}

.canceled {
  color: rgba(252, 94, 94, 1) !important;
}

.scheduled {
  color: rgba(255, 192, 0, 1) !important;
}

.text-disabled {
  color: var(--disabled) !important;
}

.general-text {
  color: var(--text-color);
}

.text-blue {
  color: var(--curagita-blue) !important;
}

.text-inactive {
  color: var(--inactive);
}

.icon-inactive {
  color: #d9d9d9 !important;
}

.text-white {
  color: #ffff !important;
}

.color-noName {
  color: rgb(255, 204, 255) !important;
}

.bg-orange {
  background: var(--color-orange);
}

.text-orange {
  color: var(--color-orange);
}
.text-dark-gray {
  color: var(--text-dark-gray);
}
.bg-blue {
  background: var(--color-blue);
}

.bg-purple {
  background: var(--color-purple);
}

.bg-standard-grey {
  background: var(--standard-grey);
}

.text-grey {
  color: var(--dark-grey) !important;
}

.text-light-grey {
  color: var(--color-grey) !important;
}

.text-light-black {
  color: var(--text-light-black) !important;
}

.text-standard-grey {
  color: var(--standard-grey);
}

.text-sm {
  font-size: 14px;
}

.color-grey {
  background: var(--color-grey);
}

.text-yellow {
  color: var(--dark-yellow) !important;
}

.light-yellow-text {
  color: var(--light-yellow) !important;
}

.dark-yellow-text {
  color: var(--dark-yellow);
}

.text-dark {
  color: var(--light-black) !important;
}

.bg-green {
  background: var(--color-green);
}

.bg-pink {
  background: var(--color-pink);
}

.text-green {
  color: var(--color-green) !important;
}

.text-pink {
  color: var(--color-pink) !important;
}

.text-error {
  color: var(--text-red) !important;
  font-weight: 400 !important;
}

.text-purple {
  color: var(--color-purple);
}

.teams-purple {
  color: rgb(204, 102, 255);
}

.h2-title {
  margin-bottom: 32px;
  font-weight: 600;
  font-size: 20px;
}

.back-btn {
  color: #cec6c4;
  background: rgba(206, 199, 197, 0.1);
  border-radius: 25px;
  padding: 10px 30px;
}
.text-balance {
  text-wrap: balance;
}

#toPrint {
  position: relative;

  & > * {
    font-family: "Sarabun", sans-serif;
  }
}

.z-index-1 {
  z-index: 1;
}

.child-text-muted {
  color: var(--color-grey) !important;
}

.text-underline {
  text-decoration: underline;
}

.text-muted {
  color: var(--dark-grey) !important;
}

.cursor-default {
  cursor: default !important;
}

a:hover {
  text-decoration: underline;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-disabled {
  cursor: not-allowed !important;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.font-700 {
  font-weight: 700 !important;
}

.p-12 {
  padding-left: 12px;
}

.m-text {
  font-size: 18px;
}

.l-text {
  font-size: 25px;
}

.gap {
  gap: 20px;
}

button {
  outline: none;
  border: none;
  background: transparent;
  // text-transform: capitalize;
  cursor: pointer;

  &:focus {
    outline: none;
    //border: none;
  }
}

.button {
  color: #fff;
  padding: 16px 30px;
  border-radius: 30px;
  background: var(--curagita-blue);
  // text-transform: capitalize;
  border: none;
  color: var(--Text-White, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.15);
  &:disabled {
    cursor: not-allowed;
    color: #cec6c4;
    background: rgba(206, 199, 197, 0.1);
  }
}

.link-btn {
  padding: 10px 30px;
  border-radius: 25px;
  text-transform: capitalize;
  font-size: 16px !important;
  color: var(--color-blue);
  background: transparent;
  font-weight: 700;
}

.bg-button {
  background-color: var(--button-bg);
}

.color-dark {
  color: var(--dark-color);
}

.justify-content-right {
  justify-content: right !important;
}

button:disabled {
  cursor: not-allowed !important;
}

select {
  background: transparent;
  color: var(--dark-color);
}
.mainContent {
  margin-top: 64px;
  margin-left: 64px;
  padding: 80px;
  .contentInner {
    margin-top: 56px;
  }
}
.navbar {
  background: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.navbar .my-nav-logo {
  color: var(--dark-color);
}

/*Landing Page*/
.Header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1800;
}

.my-navbar {
  padding-left: 70px;
  padding-right: 70px;
  font-size: 15px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: transparent;
  padding-top: 20px;
}

.google_translate_element {
  display: inline-block;
}

.goog-tooltip {
  display: none !important;
}

.goog-tooltip:hover {
  display: none !important;
}

.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

#goog-gt-tt {
  visibility: hidden !important;
  display: none !important;
}

.borderLine {
  @extend .bg-blend;
  height: 2px;
  margin-top: 0px;
}

.goog-tooltip {
  display: none !important;
}

.goog-tooltip:hover {
  display: none !important;
}

.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.my-nav-logo {
  font-size: 22px;
  font-weight: 700;
}

textarea {
  height: 150px;
}

.text-upper {
  text-transform: capitalize !important;
}

.text-capitalise::first-letter {
  text-transform: capitalize;
}

input[type="checkbox"]:disabled {
  background: #dddddd;
  cursor: not-allowed;
}

.end {
  font-size: 14px;
}

.nav-wrapper {
  background: #fff;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid var(--color-grey);
  align-items: center;
}

.nav-title {
  font-size: 24px;
  // width: 16vw;
  line-height: 1.2em;
  max-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @extend .text-blend;
}

.company-title {
  font-size: 34px;
  @extend .text-blend;
  img {
    width: 50px;
    height: 50px;
  }
}

/* Dropdown Button */
.my-dropbtn {
  background: transparent;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  border: 1px solid var(--color-blue); // min-height: 43px;
  margin-top: 15px;

  svg {
    width: 15px;
    height: 15px;
  }
}

.my-dropbtn-ghost {
  background: var(--standard-grey);
  color: var(--color-blue);
  font-weight: 600;
  cursor: pointer;
  border: none;
  margin-top: 10px;
  // text-transform: capitalize;

  .text {
    font-size: 16px;
    // margin-right: 10px;
  }
}

.icon-btn {
  background: var(--standard-grey);
  color: var(--color-blue);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 25px;
  text-align: center;
  display: block;
  margin-left: auto;
}

/* The container <div> - needed to position the dropdown content */
.my-dropdown {
  position: relative;
  display: inline-block;
  font-size: 16px;
  // z-index: 4;
  // z-index: 1;
}

.dropdown-content {
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  background: white;
  right: 5px;
  z-index: 1050 !important;
  border-radius: 15px;
  top: 65px;
  // text-transform: capitalize;

  & > *,
  .list-content > * {
    color: var(--text-color);
    padding: 6px 12px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    font-size: 14px !important;
    font-weight: 500;

    &:first-child:hover {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    &:last-child:hover {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
}

.list-content > * {
  color: var(--text-color);
  padding: 6px 12px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  font-size: 16px !important;
  font-weight: 500;
  text-transform: capitalize;

  // &:hover {
  //   opacity: 0.4 !important;
  // }
}

.list-content {
  display: grid;
  padding-top: 0px;
  padding-left: 12px;

  &:hover {
    opacity: 1 !important;
  }
}

.cardsWrapper {
  margin-bottom: 25px;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(5, 1fr);

  .card {
    overflow: hidden;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    position: relative;
  }
  @media screen and (min-width: 1441px) {
    // grid-gap: 20px;
    .card {
      aspect-ratio: 3.5/4;
    }
  }
}
.jobDisplay {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  height: 300px;
  padding: 10px;
  .card {
    width: 320px;
  }
}
.miniCardsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 25px;
  padding: 0px 5px;
  .card {
    height: 300px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    position: relative;
    width: 200px;
  }
}
.startMsgDropdown {
  left: 30px;
  right: 0px;
  min-width: 250px;
  width: max-content;
  min-height: 250px;
  max-height: 300px;
  padding: 0px 20px;
  overflow-y: scroll;
}

.my-icon-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-icons {
  position: absolute;
  background-color: #fff;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 15px;
  top: 30px;
  right: 0px;

  svg {
    width: 23px;
    height: 23px;
  }
}

.signup-text {
  font-size: 16px !important;
  color: rgb(179, 179, 0);
}

.row-section {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 1200px) {
    //gap: 10px;
  }
}

.side-column {
  flex: 30%;
}

.main-content {
  margin-top: 64px;
  margin-left: 64px;
  padding: 80px;
  margin-top: 0;
  padding-top: 0;
}

.main-column {
  flex: 70%;
  padding-right: 20px;
  color: var(--text-color);
  max-width: 70%;
}

.main-columnC {
  padding-right: 30px;
}

// code,
.code {
  padding: 1rem;
  background-color: #efefefef;
  font-size: 14px;
  border-radius: 0.4rem;
  width: 60%;
}

.video-tutorial {
  height: 20rem;
  width: 35.6rem;
  border: none;
}

.focus {
  color: var(--color-blue) !important;
}

.right {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: right !important;
  max-width: 100%;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  justify-content: right;
  min-width: 0;
}

.form-title {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-color);
}

.form-text {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-color);
}

.form-flex {
  display: flex;
  flex-wrap: wrap;
}

.form-control:read-only {
  // background: none;
  background-color: transparent;
}

.opt {
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid var(--color-blue);
  color: var(--color-blue);
  border-radius: 15px;
  text-transform: uppercase;
  height: 22px;
  display: flex;
  align-items: center;
  cursor: text;
}

.req {
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid var(--color-blue);
  background: var(--color-blue);
  color: #fff;
  height: 22px;
  text-transform: uppercase;
  border-radius: 15px;
  display: flex;
  align-items: center;
  cursor: text;
}

.shadow {
  box-shadow: 0px 0px 14px 2px rgba(169, 167, 167, 0);
  -webkit-box-shadow: 0px 0px 14px 2px rgba(121, 119, 119, 1);
  -moz-box-shadow: 0px 0px 14px 2px rgba(121, 119, 119, 1);
}

.w-70 {
  width: 80%;
}

.w-45 {
  flex: 45%;
  color: var(--dark-colored);
}

.w-47 {
  flex: 47%;
}

.logo {
  background: var(--dark-color);
  height: 100vh;
  font-family: "Sarabun", sans-serif;
  padding-top: 80px !important;
}

.css-1algrk9-MuiRating-root,
.css-1lauo1g-MuiRating-root {
  color: rgb(179, 179, 0) !important;
}

.css-9cct2k.Mui-disabled {
  color: var(--color-blue) !important;
}

.languages-section {
  margin: 50px 0px 100px 0px;
  padding: 10px;
}

.scroll-tags {
  overflow-x: scroll;
  // padding: 0px 0px 0px 5px;
}

.scroll-tags::-webkit-scrollbar {
  display: none;
}

.marquee-wrapper {
  max-width: 100%;
  overflow: hidden;
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: marquee 15s linear infinite;
}

.marquee p {
  display: inline-block;
}

.marquee p span {
  font-size: 32px;
  font-weight: 600;
  @extend .text-blend;
}

.footer-text {
  font-size: 16px;
  text-transform: none;
  font-weight: 500;
  color: #fff;
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.home-menu {
  padding-right: 70px;
}

.verify_exit_btn {
  padding-top: 15px;
  padding-right: 70px;
}

.footer-sm-text {
  font-size: 16px;
  color: var(--text-color);
}

.footer-lg-text {
  font-size: 22px;
}

.search-page-filters {
  background-color: #fff;
}

.online,
.offline {
  width: 20px;
  height: 20px;
  color: var(--color-green);
  position: absolute;
  top: 55px;
  left: 55px;
}

.offline {
  color: #cec6c4;
}

.footer-images {
  height: 40px;
}

.taggedUsers {
  color: var(--text-color);

  &:hover {
    color: var(--color-blue);
    text-decoration: underline !important;
  }
}

.underline-on-hover {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.not-underline-on-hover {
  &:hover {
    text-decoration: none;
  }
}

.bold-text {
  font-weight: 700 !important;
}

.ellipsis-text {
  line-height: 1.2em;
  max-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.scroll-section-with-hidden-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;
}

.scroll-section-with-hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.recharts-text {
  font-size: 14px;
  // font-weight: 600;
  text-transform: capitalize;
}

.custom-tooltip {
  border: none !important;
  outline: none !important;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;

  &:hover {
    border: none !important;
    outline: none !important;
  }
}

.label {
  margin: 0;
  font-weight: bold;
}

.custom-tooltip-wrapper .recharts-tooltip-wrapper {
  border: none !important;
  outline: none !important;
}

.custom-tooltip-wrapper .recharts-tooltip {
  border: none !important;
  outline: none !important;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.custom-tooltip-wrapper .label {
  margin: 0;
  font-weight: bold;
}

.recharts-cartesian-axis-tick-line {
  display: none;
}

.recharts-cartesian-axis-line {
  display: none;
}

.scroll-section-with-hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.recharts-layer {
  .recharts-pie-label-line {
    display: none !important;
  }
}

.recharts-responsive-container {
  width: 100%;
}

.pie-chart {
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.chart {
  width: 100%;
  height: 250px;
}

.chart-tree {
  width: 100%;
  height: 235px;
  margin-top: 30px;
}

.logo_img {
  width: 70px;
  object-fit: contain;
}

.fixed-table-header {
  position: sticky;
  z-index: 4;
  top: 0px;
  background-color: #fff;
}

.fixed-table-sub-header {
  left: 0;
  background-color: #fff;
  position: sticky;
}

.f-700 {
  font-weight: 700 !important;
}

.f-600 {
  font-weight: 600 !important;
}

.f-500 {
  font-weight: 500 !important;
}

.f-400 {
  font-weight: 400 !important;
}

.f-300 {
  font-weight: 300 !important;
}

.bg-gray {
  background-color: rgba(221, 221, 221, 0.3) !important;
}

.h-24 {
  height: 24px !important;
}

.h-23 {
  height: 23px !important;
}

.h-21 {
  height: 21px !important;
}

.h-35 {
  height: 35px !important;
}

.w-9 {
  width: calc(100% / 9);
}

.w-10 {
  width: calc(100% / 10);
}

.w-2 {
  width: calc(100% / 2);
}

.w-3 {
  width: calc(100% / 3 - 30px) !important;
}

.w-4 {
  width: calc((100% / 4) - 40px) !important;
}

.w-4 {
  width: calc((100% / 4) - 20px) !important;
}

.w-5 {
  width: calc(100% / 5) !important;
}

.w-6 {
  width: calc((100% / 6) - 20px) !important;
}

.w-7 {
  width: calc((100% / 7) - 20px) !important;
}

.w-40 {
  width: 40%;
}

.w-20 {
  width: 20%;
}

.border-left-none {
  border-left: none !important;
}

.border-top-none {
  border-top: none !important;
}

.blue-border-left {
  border-left: 3px solid var(--curagita-blue);
}

#test-table-xls-button {
  padding: 0;
}

.editable-table-input-group,
.editable-table-input-group-kpi {
  color: #3b3b3b;
  width: -webkit-fill-available;
  border-bottom: 0.2px solid #dcdcdc;
  border-right: 0.2px solid #dcdcdc;
  background-color: rgba(0, 159, 227, 0.05);

  input {
    color: inherit;
    background-color: transparent;
    &:focus-visible {
      outline-style: none;
    }

    &::placeholder {
      color: var(--disabled);
    }
    &:disabled {
      background-color: #f2f2f2;
    }
  }

  &:focus-within {
    border: 1.2px solid var(--color-blue) !important;
    input {
      height: 35px !important;
    }
  }
}

.editable-table-input-group-kpi {
  border-bottom: 1px solid var(--color-grey);
  border-right: 1px solid var(--color-grey);
  margin-top: 0px;
  margin-bottom: 0px;
  input {
    color: var(--text-color);
    border: none;
  }
  &:focus-within {
    border: 0.5px solid var(--color-blue) !important;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.editable-input-group-deratek {
  border-bottom: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  input {
    color: var(--light-black);
    border: none;
    &::placeholder {
      color: var(--Inactive, #aeaeae);
      font-weight: 300;
    }
    &:focus-visible {
      outline-style: none;
    }
  }
  &:focus-within {
    border: 0.4px solid var(--curagita-blue);
    input {
      height: 37.5px !important;
      width: 199.5px !important;
    }
  }
}

.deratek-select {
  padding: 5.5px 0px !important;
}

.deratek-btn,
.deratek-text-input {
  border: none;
  border-radius: 0px;
  &:disabled {
    background: var(--Light-Gray, #f2f2f2);
    color: var(--dark-gray);
  }
}

.top-input-deratek {
  border-right: 1px solid #fff;
  border-bottom: 2px solid var(--curagita-blue) !important;
  &:focus-within {
    border-top: none;
    border-left: none;
    border-right: none;
    input {
      height: 38px !important;
      width: 100% !important;
    }
  }
}

.deratek-file-input {
  &:focus-within {
    padding: 3.75px 0px !important;
  }
}

.top-kpi-input {
  &:focus-within {
    padding: 0.5px;
    padding-bottom: 0.5px;
  }
}

.top-level-input {
  border: 0.2px solid #dcdcdc;
  &:focus-within {
    border-top: 1.2px solid var(--color-blue) !important;
    border-bottom: 1.2px solid var(--color-blue);
    input {
      height: 35px !important;
    }
  }
}

.top-level-two-input {
  &:focus-within {
    border: 1.2px solid var(--color-blue) !important;
    border-top: none !important;
  }
}

.bottom-level-two-input {
  &:focus-within {
    border: 1.2px solid var(--color-blue) !important;
    border-bottom: none !important;
    input {
      height: 35px !important;
    }
  }
}

.white-border-left-md {
  border-left: 1px solid #fff;
}

.gray-border-left-md {
  border-left: 1px solid #dcdcdc;
}

.gray-border {
  border: 0.2px solid #dcdcdc;
}

.gray-border-left {
  border-left: 0.2px solid #dcdcdc;
}

.gray-border-right {
  border-right: 0.2px solid #dcdcdc;
}

.gray-border-top {
  border-top: 0.2px solid #dcdcdc;
}

.gray-border-bottom {
  border-bottom: 0.2px solid #dcdcdc;
}

.blue-border-bottom {
  border-bottom: 0.4px solid var(--curagita-blue);
}

.thick-blue-border-bottom {
  border-bottom: 1px solid var(--curagita-blue);
}

.thicker-blue-border-bottom {
  border-bottom: 2px solid var(--curagita-blue) !important;
}

.thick-black-border-bottom {
  border-bottom: 1px solid var(--light-black);
}

.thick-blue-border-top {
  border-top: 3px solid var(--curagita-blue);
}

.thick-blue-border-top {
  border-top: 1.5px solid var(--curagita-blue);
}

.caption {
  background: var(--light-blue);
  color: var(--color-blue);
  font-weight: 700;
  width: fit-content;
  padding: 6px 9px;
  border-radius: 25px;
  text-transform: capitalize;
  font-size: 14px;
  margin-right: 6px;
}

#first .recharts-bar:nth-of-type(4),
#first .recharts-reference-line,
#second .recharts-bar:nth-of-type(4),
#second .recharts-reference-line,
#third .recharts-bar:nth-of-type(4),
#third .recharts-reference-line {
  transform: translateX(15px);
}

.investitionMap {
  margin-top: 30px;
}

.bg-transparent {
  background-color: transparent;
}

.report-section {
  width: 25%;
}
.MuiTooltip-arrow {
  color: #fff !important;
}
.MuiTooltip-tooltip,
.MuiTooltip-tooltipArrow {
  font-size: 14px !important;
  background-color: #fff !important;
  color: var(--text-color) !important;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.10);
  max-width: 300px !important;
  font-weight: 400 !important;
  
}

.MuiTooltip-tooltip{
  border-radius: 10px !important;
}

.MuiTooltip-popper {
  // margin-top: -10px;
  z-index: 999 !important;
}

.color-green {
  color: #63be7b !important;
}

.color-red {
  color: #f8696b !important;
}

.one-line-text-table {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.one-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-height: 1.5em;
}

.two-lines-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 3em;
}

marquee {
  display: inline-block; /* Display spans horizontally */
  padding: 0 20px; /* Adjust spacing as needed */
  color: #009dd9;
  font-size: 20px;
  font-weight: 600;
}

.slick-track {
  display: grid !important;
  column-gap: 24px;
  margin: 20px 0px !important; /* Add some margin around the grid */
  grid-auto-flow: column;
  // width: -webkit-fill-available !important;
}

// .slick-slide {
//   width: auto !important;
// }

.slick-list {
  width: -webkit-fill-available;
}

/* Apply basic styling to the card */
.card {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  position: relative;
  // overflow: hidden;
  aspect-ratio: 3/4;
}

/* Style the card image */
.card img {
  object-fit: cover;
  object-position: center;
}

/* Style the card content */
.card-content {
  padding: 16px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  grid-auto-rows: 374px;
  grid-gap: 32px;
  grid-auto-flow: row dense;

  @media (max-width: 1599px) {
    grid-template-columns: repeat(4, minmax(0px, 1fr));
    grid-row-gap: 32px;
    grid-column-gap: 20px;
  }

  @media (max-width: 1399px) {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
  }

  @media (max-width: 1200px) {
    grid-auto-rows: minmax(min-content, max-content);
  }

  @media (max-width: 990px) {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.jobCards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}

// stylings for ratings
.article-rating {
  font-size: 70px !important;
}

// stylings for react quill
.ql-editor {
  font-family: "Sarabun", sans-serif;
  font-size: 16px;
}

.ql-snow .ql-tooltip {
  left: 10px !important;
}

.resizable-quill-container {
  position: relative;
  resize: vertical; /* Enable resizing */
  overflow: auto; /* Enable scrolling when resized */
  &:focus-within {
    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border: 1px solid var(--curagita-blue) !important;
    }
    .ql-container.ql-snow {
      border-top: 0px !important;
    }
  }
}
.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: 1px solid var(--text-inactive) !important;
  padding: 15px !important;
}

.error-input {
  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    border: 1px solid red !important;
  }
  .ql-container.ql-snow {
    border-top: 0px !important;
  }
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-top: 0px !important;
  height: calc(100% - 58px);
}

.curafinance-section-container {
  min-width: 2000px;
  width: 100%;
  position: relative;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-100 {
  opacity: 1;
}

.rotate {
  animation: rotation 2s infinite linear;
}

.MuiTooltip-tooltip {
  max-width: 400px !important;
}

.pie-chart-count {
  position: absolute !important;
  font-size: 20px !important;
  top: 55%;
  left: 48%;
}

body{
  .modal-open{
    overflow: hidden;
    position: fixed;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media (min-width: 576px) {
  .w-sm-75 {
    width: 75% !important;
  }
}

@media (min-width: 778px) {
  .w-md-50 {
    width: 50% !important;
  }
}

@media (min-width: 992px) {
  .invisible-lg {
    visibility: hidden !important;
  }
}

@media (max-width: 680px) {
  .ql-container.ql-snow {
    height: calc(100% - 83px);
  }
}

/* Style for smaller screens */

/* Style for smaller screens */
@media (max-width: 550px) {
  // / stylings for ratings
  .article-rating {
    font-size: 50px !important;
  }
}

@media (max-width: 440px) {
  .ql-container.ql-snow {
    height: calc(100% - 109px);
  }
}
